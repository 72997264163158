/*
    Přidání potahu
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CUSTOMER} from '../Queries/customer.js';
import CustomerLib from '../Library/customer';
import Notification from '../../../../Library/notification';
import Helper from '../../../../Library/helper';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import DeliveryAddress from './DeliveryAddress';
import CustomerAccount from './CustomerAccount';

const INITIAL_STATE = {
    apolloError:"",
    selectedCustomerID:null,
    allCountries:[],
    selectedTab:1,
    formData:{
        customerID:0,
        sapCode:"",
        name:"",
        surname:"",
        company:"",
        email:"",
        tel:"",
        street:"",
        streetNumber:"",
        city:"",
        zip:"",
        countryID:60,
        dic:"",
        ic:"",
        description:""
    }
}

class AddEditCustomer extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.customer = new CustomerLib(this); 
        this.customer.getData();
        
    }
    
    render(){

        const {selectedCustomerID,apolloError,allCountries,selectedTab} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_CUSTOMER}
                update = {async (cache, response) => {

                    this.customer.updateCustomerList(cache,response,selectedCustomerID,this.props.customerListVariables);

                    let notify = new Notification();

                    if(selectedCustomerID && selectedCustomerID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {
                    this.props.openCloseModal(false);   
                }}
                
            >
            {
                (addCustomer,{data,loading,error}) => {

                    const {
                        sapCode,name,surname,company,email,tel,street,streetNumber,city,zip,countryID,dic,ic,description,
                    } = this.state.formData;

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedCustomerID && selectedCustomerID != 0 ? "Úprava zákazníka" : "Přidání zákazníka")}</h4>
                                    <button type="button" className="close" onClick={() => this.props.openCloseModal(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.customer.showTab(e,1)}>Základní údaje</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 2 ? "nav-link active" : (selectedCustomerID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(selectedCustomerID)this.customer.showTab(e,2)}}>Doručovací adresy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={(selectedTab == 3 ? "nav-link active" : (selectedCustomerID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(selectedCustomerID)this.customer.showTab(e,3)}}>Uživatelské účty</a>
                                        </li>
                                    </ul>

                                    {selectedTab  == 1 &&
                                    <div>
                                        {!loading ?
                                            <Fragment>
                                                {error || apolloError ?
                                                    <Error text={error || apolloError} />
                                                :
                                                    <div className="row">
                                                                                                                  
                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Jméno</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="name" value={name} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Příjmení</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="surname" value={surname} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-1"></div>

                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">*Společnost</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="company" value={company} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Ulice</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="street" value={street} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <label className="input-label">*Č. popisné</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="streetNumber" value={streetNumber} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <label className="input-label">*PSČ</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="zip" value={zip} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        

                                                        <div className="col-12 col-sm-1"></div>

                                                        {false &&
                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">IČ</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="ic" value={ic} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>
                                                        }

                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">*DIČ</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="dic" value={dic} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Město</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="city" value={city} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        {false ? 
                                                            <div className="col-12 col-sm-4">
                                                                <label className="input-label">*Stát</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="countryID" value={countryID} onChange={(e) => this.customer.formHandle(e)}>
                                                                        <option value="0"> --- vyberte stát --- </option>  
                                                                        {allCountries && allCountries.map((item,index) => (
                                                                            <option key={index} value={item.countryID}> {item.title} </option> 
                                                                        ))}  
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-12 col-sm-4"></div>

                                                        }

                                                        <div className="col-12 col-sm-1"></div>

                                                        <div className="col-12 col-sm-3">
                                                            <label className="input-label">SAP kód</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="sapCode" value={sapCode} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Email</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="email" value={email} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">*Tel.</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="tel" value={tel} onChange={(e) => this.customer.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        {false && 
                                                            <div className="col-12">
                                                                <label className="input-label">Interní poznámka</label>
                                                                <div className="form-group">
                                                                    <TinyMCEEditor onChange={(data) => this.customer.setDescription(data)} initialValue={description} />
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </Fragment>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
                                    }
                                    {selectedTab == 2 && <DeliveryAddress customerID = {selectedCustomerID}  />}
                                    {selectedTab == 3 && <CustomerAccount customerID = {selectedCustomerID}  />}
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.customer.addCustomer(addCustomer)}>{(selectedCustomerID && selectedCustomerID != 0 ? "Upravit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCustomer);