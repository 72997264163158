/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací doručovacích adres
 */

import {GET_CUSTOMER_ACCOUNT,GET_CUSTOMER_ACCOUNTS,GET_CUSTOMER_ACCOUNT_BY_EMAIL} from '../Queries/customerAccount.js';
import Notification from '../../../../Library/notification';
import Helper from '../../../../Library/helper';

class CustomerAccount {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
     */

    async getData(){

        var customerAccountID = 0;
        if(this.parent.props.selectedCustomerAccountID)customerAccountID = this.parent.props.selectedCustomerAccountID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_CUSTOMER_ACCOUNT,
            errorPolicy:"all",
            variables:{customerAccountID}, 
            fetchPolicy: 'network-only'
        });

        console.log(data);

        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }

        if(data.data){
            
            if(data.data.customerAccount){
                
                this.parent.setState({
                    apolloError:"",selectedCustomerAccountID:customerAccountID,
                    formData:{...this.parent.state.formData,
                        customerAccountID: data.data.customerAccount.customerAccountID,
                        email:         data.data.customerAccount.email,
                        tel:           data.data.customerAccount.tel
                    }
                });

            }
        }
    }

    /**
     * Přidání nebo úprava nového zákazníka
     * @param {*} addCustomerAccount funkce z apollo, pomocí které se posílají data na server
     */
    async addCustomerAccount(addCustomerAccount,customerID){

        const data = this.parent.state.formData;
        let notify = new Notification();   
                  
        if(data.tel != ""){

            const helper = new Helper();
            if(data.email != "" && helper.validateEmail(data.email)){

                var custEmail = await this.parent.props.client.query({ 
                    query: GET_CUSTOMER_ACCOUNT_BY_EMAIL,
                    errorPolicy:"all",
                    variables:{email:data.email, customerID:customerID, customerAccountID:data.customerAccountID}, 
                    fetchPolicy: 'network-only'
                });
        
                if(custEmail.errors){
                    this.parent.setState({apolloError:custEmail.errors[0].message});
                }else{

                    if(custEmail.data && custEmail.data.customerAccountByEmail){
                        notify.setNotification(null,'Tento email už je v databázi uložen.',false,true,this.parent.props.client); 
                    }else{
        
                        data.customerID = customerID;
                        addCustomerAccount({
                            variables:data
                        });  

                    }

                }
            
            }else{
                notify.setNotification(null,'Email není ve správném tvaru.',false,true,this.parent.props.client); 
            }

        }else{
            notify.setNotification(null,'Nevyplnili jste telefon.',false,true,this.parent.props.client); 
        }     
                                
                           

    }

    /**
     * Při vložení nebo úpravě uživatelského účtu dojde k update cache a vykreslí se opět seznam veškerých uživatelských účtu
     * @param {*} cache apollo cahe v které jsou oložena data
     * @param {*} response položka, která byla vložena nebo upravována
     */

    updateCustomerAccountList(cache,response,selectedCustomerAccountID,customerID){

        const { allCustomerAccounts } = cache.readQuery({ query: GET_CUSTOMER_ACCOUNTS,variables:{customerID}});

        if(!(selectedCustomerAccountID && selectedCustomerAccountID != 0)){

            cache.writeQuery({ 
                query:GET_CUSTOMER_ACCOUNTS,
                variables:{customerID},
                data:{
                    allCustomerAccounts: [response.data.addEditCustomerAccount,...allCustomerAccounts]
                } 
            });

        }else{

            var arr = [...allCustomerAccounts];
            
            arr.forEach((item,index) => {
                if(item.customerAccountID == response.data.addEditCustomerAccount.customerAccountID){
                    arr[index] = response.data.addEditCustomerAccount;
                }

            });

            cache.writeQuery({ 
                query:GET_CUSTOMER_ACCOUNTS,
                variables:{customerID},
                data:{
                    allCustomerAccounts: arr
                } 
            });
        }
    }

    /**
     * Update seznamu doručovacích adres po smazání vybraných doručovacích adres
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     */
    
    updateAfterDeleteCustomerAccount(cache, response,customerID){

        var resp = response.data.deleteCustomerAccounts.split(",");

        const { allCustomerAccounts } = cache.readQuery({ query: GET_CUSTOMER_ACCOUNTS,variables:{customerID}});
        var arr = [...allCustomerAccounts];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.customerAccountID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
        
        cache.writeQuery({ 
            query:GET_CUSTOMER_ACCOUNTS,
            variables:{customerID},
            data:{
                allCustomerAccounts: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default CustomerAccount;