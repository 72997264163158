/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState,useRef } from 'react';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Notification from '../../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_ORDERS,DELETE_ORDERS,CHANGE_ORDER_STATUS} from '../Queries/order';
import Loading from '../../../../GlobalComponents/Loading';
import NoItems from '../../../../GlobalComponents/NoItems';
import OrderLib from '../Library/order';
import showDetail from '../../../../Public/Images/eye-open.svg';
import checkEnabled from '../../../../Public/Images/check_enabled.svg';
import checkDisabled from '../../../../Public/Images/check_disabled.svg';
import calendar from '../../../../Public/Images/calendar.svg';
import moment from "moment";
import Helper from '../../../../Library/helper';
import Pagination from "react-js-pagination";
import SearchInput from '../../../../GlobalComponents/SearchInput';
import OrderDetail from './OrderDetail';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

const Orders = (props) => {
        
    const dateFromRef                               = useRef();
    const dateToRef                                 = useRef();
    const [selectedOrders,setSelectedOrders]        = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showOrderDetail,setShowOrderDetail] 		= useState(false);
    const [selectedOrderID,setSelectedOrderID]      = useState(0);

    const [orderCount,setOrderCount]        = useState(0);
    const [selectedPage,setSelectedPage]    = useState(1);
    const [searchText,setSearchText]        = useState("");
    const [dateFrom,setDateFrom]            = useState(null);
    const [dateTo,setDateTo]                = useState(null);

    const [dataForStatusChange,setDataForStatusChange]          = useState({
        orderID:0,
        status:0,
        showChangeOrderStateNotifi:false
    });
    

    const [filterParams,setFilterParams] = useState({
        onPage:20,
        offset:0,
        searchQuery:"",
        orderBy:"",
        orderDirection:"DESC",
        dateFrom:"",
        dateTo:"",
        processed:""
    });

    const {loading, error:getError, data:orderListData, refetch}   = useQuery(GET_ORDERS,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            filterParams
        },
        onCompleted(data){
            setOrderCount(data.orderForAdminCount);
        }
    });
	const [deleteOrder, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ORDERS,{	
		update(cache,response) {    
            
            refetch();
            let notify = new Notification();
            notify.setNotification(cache,"Úspěšně smazáno",true,false);
            setShowDeleteNotifi(false);            
            setSelectedOrders([]);

            /*
            let order = new OrderLib(this);
            order.updateAfterDelete(cache, response,filterParams);

            */
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });
	const [changeOrderStatus, { data:statData,loading: statLoading, error: statError }] = useMutation(CHANGE_ORDER_STATUS,{	
		update(cache,response) {  
            refetch();
            let notify = new Notification();
            notify.setNotification(cache,"Status úspěšně upraven",true,false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });


    const selectPage = (page) => {
        setSelectedPage(page);
        setFilterParams({...filterParams,offset:(page - 1) * filterParams.onPage});
    }
    /**
     * smazání štítků
     * @param {*} deleteOrders funkce, která posílá data na server
     * @param {*} orderID ID orderu
     */
    
    const initiateDeleteOrder = (e,orderID) => {

        var checked = e.target.checked;
        var arr = [...selectedOrders];

        if(!checked){
            for(let i in selectedOrders){
                if(selectedOrders[i] == orderID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [orderID,...arr];
        }

        setSelectedOrders(arr);
  
    }
    
    const initiateChangeOrderStatus = (status,orderID) => {
        setDataForStatusChange({
            orderID,
            status:status ? 1 : 0,
            showChangeOrderStateNotifi:true
        });
    }
    
    const changeOrderState = (action) => {

        if(action){
            changeOrderStatus({
                variables:{
                    orderID: dataForStatusChange.orderID,
                    status:dataForStatusChange.status
                }
            })
        }
        setDataForStatusChange({
            orderID:0,
            status:0,
            dataForStatusChange:false
        });
        //this.setState(INITIAL_STATE);
    }
    const delOrder = (action) => {

        if(action){

            let orderIDs = selectedOrders.join(",");

            deleteOrder({
                variables:{
                    orderIDs: orderIDs
                }
            })
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedOrders.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,"Nevybrali jste žádné položky ke smazání",false,true,props.client);
        }
    }

    const setOrder = (orderBy) => {
        if(orderBy == filterParams.orderBy){
            setFilterParams({...filterParams,offset:0,orderDirection: filterParams.orderDirection == "ASC" ?  "DESC" : "ASC"});
        }else{
            setFilterParams({...filterParams,offset:0,orderBy,orderDirection:"ASC"});
        }
        setSelectedPage(1);
    }
    const searchCompany = (searchQuery) => {
        setSearchText(searchQuery);
        setSelectedPage(1);
        setFilterParams({...filterParams,searchQuery,offset:0});
    }
    const setDateFromInput = (dateFrom) => {
        setSelectedPage(1);
        setDateFrom(dateFrom);
        setFilterParams({...filterParams,dateFrom:moment(dateFrom).format("YYYY-MM-DD"),offset:0});
    }
    const setDateToInput = (dateTo) => {
        setSelectedPage(1);
        setDateTo(dateTo);
        setFilterParams({...filterParams,dateTo:moment(dateTo).format("YYYY-MM-DD"),offset:0});
    }
    const setprocessedFilter = (processed) => {
        setSelectedPage(1);
        setFilterParams({...filterParams,processed,offset:0});
    }
    const clearFilter = (dateTo) => {
        setSelectedPage(1);
        setDateFrom(null);
        setDateTo(null);
        setSearchText("");
        setFilterParams({
            onPage:20,
            offset:0,
            searchQuery:"",
            orderBy:"",
            orderDirection:"DESC",
            dateFrom:"",
            dateTo:"",
            processed:""
        });
    }
    
    const openCloseOrderDetailModal = (open,orderID) => {
        setSelectedOrderID(orderID);
        setShowOrderDetail(open);
    }
    
    
    return(

        <div id="settings" className="whole-container" >
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Seznam objednávek
                            <div className="ml-auto d-flex align-items-center mr-5">
                                <label className="m-0 bold mr-3">Firma</label>
                                <SearchInput value={searchText} className="form-control" placeholder="Vyhledat firmu" onChange={(text) => searchCompany(text)} />

                            </div>
                            <div className="ml-auto d-flex align-items-center mr-5">
                                <label className="m-0 bold mr-3">Typ</label>
                                <select className="form-control" value={filterParams.processed} onChange={(e) => setprocessedFilter(e.target.value)}>
                                    <option value="">Všechny</option>
                                    <option value="1">Zpracované</option>
                                    <option value="0">Nezpracované</option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center mr-5">
                                <label className="m-0 bold mr-3">Datum</label>
                                <div className="mr-2">od</div>
                                <div className="date-input mr-3">                                                        
                                    <DatePicker ref={dateFromRef} onKeyDown={e => e.preventDefault()} autoComplete="off" className="form-control w-100" dateFormat="yyyy-MM-dd" locale={"cs"} selected={dateFrom} onChange={(date) => setDateFromInput(date)} />
                                    <img onClick={() => dateFromRef.current.handleFocus()} src={calendar} />
                                </div>
                                <div className="mr-2">do</div>
                                <div className="date-input">                                                        
                                    <DatePicker  ref={dateToRef} onKeyDown={e => e.preventDefault()} autoComplete="off" className="form-control w-100" dateFormat="yyyy-MM-dd" locale={"cs"} selected={dateTo} onChange={(date) => setDateToInput(date)} />
                                    <img onClick={() => dateToRef.current.handleFocus()} src={calendar} />
                                </div>
                            </div>
                            <button className="btn btn-danger btn-thiner mr-2" onClick={() => clearFilter()}>Zrušit filtr</button>
                            <button className="btn btn-danger btn-thiner" onClick={() => showDelNotifi()}>Smazat označené</button>
                        </div>
                        <div className="card-body">
                            <div className="data-list">
                                
                                <div className="data-list-item header">
                                    <div className="data-list-item-content">
                                        <div className="d-flex align-items-center">
                                            <div className="cont pointer" onClick={() => setOrder("O.dateAdd")}>
                                                Datum 
                                                <div className="arrows">
                                                    <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.dateAdd" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                    <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.dateAdd" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont pointer" onClick={() => setOrder("C.sapCode + 0")}>
                                                SAP kód 
                                                <div className="arrows">
                                                    <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "C.sapCode + 0" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                    <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "C.sapCode + 0" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont pointer" onClick={() => setOrder("O.company")}>
                                                Firma 
                                                <div className="arrows">
                                                    <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.company" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                    <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.company" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont pointer" onClick={() => setOrder("O.name")}>
                                                Objednávající 
                                                <div className="arrows">
                                                    <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.name" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                    <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.name" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont">
                                                Způsob zadání 
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont pointer" onClick={() => setOrder("O.deliveryDate")}>
                                                Termín dodání 
                                                <div className="arrows">
                                                    <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.deliveryDate" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                    <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.deliveryDate" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="cont">
                                                Místo dodání 
                                            </div>
                                        </div>
                                        <div className="text-right static small">Možnosti</div>
                                    </div>
                                </div>
                                <Fragment>
                                    {orderListData && orderListData.allOrdersForAdmin && orderListData.allOrdersForAdmin.map((item,index) => {

                                        var checked = false;
                                        for(let i in selectedOrders){
                                            if(selectedOrders[i] == item.orderID)checked = true;
                                        }
                                                                                                        
                                        return (
                                        
                                            <div key={index} className="data-list-item-content">
                                                <div>                                                    
                                                    <img onClick={() => initiateChangeOrderStatus(!item.processed,item.orderID)} title={item.processed ? "Označit jako nezpracovanou" : "Označit jako zpracovanou"} className="edit-icon mr-2" src={item.processed ? checkEnabled : checkDisabled} />
                                                    {moment(item.dateAdd).format("DD.MM.YYYY")}
                                                </div>
                                                <div className="text-center">{item.customer && item.customer.sapCode}</div>
                                                <div className="text-center">{item.company}</div>
                                                <div className="text-center">{item.name} {item.surname}</div>
                                                <div className="text-center">
                                                    {item.offerNumber ?
                                                        <>Nabídka ({item.offerNumber})</>
                                                        :
                                                        <>Vlastní specifikace zboží</>
                                                    }
                                                </div>
                                                <div className="text-center">{moment(item.deliveryDate).format("DD.MM.YYYY")}</div>
                                                {item.deliveryStreet == "" ? 
                                                    <div className="text-center">Na fakturační adresu</div>
                                                    :
                                                    <div className="text-center">{item.deliveryStreet} {item.deliveryStreetNumber}<br/>{item.deliveryCity}, {item.deliveryZip}</div>
                                                }
                                                <div className="text-right static small">
                                                    <img onClick={() => openCloseOrderDetailModal(true,item.orderID)} title="Zobrazit objednávku" className="edit-icon mr-2" src={showDetail} />
                                                    
                                                    <input title="Smazání objednávky" className="delete-checked" type="checkbox" name="deleteOrder[]" checked={checked} onChange = {(e) => initiateDeleteOrder(e,item.orderID)} />
                                                    
                                                </div>
                                            </div>
                                                    
                                        )
                                        
                                    })} 
                                </Fragment>
                            </div>
                            {!loading ? 
                                <Fragment>
                                    {orderListData && orderListData.allOrdersForAdmin && orderListData.allOrdersForAdmin.length == 0 ?
                                        <NoItems text={"Nebyly vloženy žádné objednávky"} />
                                        :
                                        <div className="d-flex align-items-center justify-content-center p-5">
                                            <Pagination
                                                activePage={selectedPage}
                                                itemsCountPerPage={filterParams.onPage}
                                                totalItemsCount={orderCount}
                                                pageRangeDisplayed={10}
                                                hideFirstLastPages={false}
                                                onChange={(number) => selectPage(number)}
                                                activeLinkclassName="selected"
                                            />
                                        </div>
                                    }
                                </Fragment>
                                :                                    
                                <Loading />                                    
                            }                                
                        </div>
                    </div>
                </div>

                {dataForStatusChange.showChangeOrderStateNotifi && <ModalNotification yesNo={true} text={dataForStatusChange.status ? "Označit objednávku jako zpracovanou?" : "Označit objednávku jako nezpracovanou?"} callback={changeOrderState} /> }
                {showDeleteNotifi && <ModalNotification yesNo={true} text={"Vymazat vybrané položky?"} callback={delOrder} /> }
                {showOrderDetail && <OrderDetail orderID={selectedOrderID} callback={(state) => setShowOrderDetail(state)} /> }
            </div> 
        </div> 	
            	
        
    );

}

export default withApollo(Orders);