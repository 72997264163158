/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import logo from '../../Public/Images/logo.png';
import menu from '../../Public/Images/menu.svg';
import door from '../../Public/Images/door.svg';
import shop from '../../Public/Images/shop.svg';
import basket from '../../Public/Images/basket.svg';
import user from '../../Public/Images/user.svg';
import orders from '../../Public/Images/notes.svg';
import content from '../../Public/Images/content.svg';
import doorLighter from '../../Public/Images/door_lighter.svg';
import settings from '../../Public/Images/cogwheel.svg';
import { ApolloConsumer} from 'react-apollo';

const INITIAL_STATE = {
	cl:"",
	showSubmenu:[
	  {menuID:1,show:false},
	  {menuID:2,show:false},
	  {menuID:3,show:false},
	  {menuID:4,show:false},
	  {menuID:5,show:false}
	]
}

class Header extends Component {
	
	  constructor(props){
		  super(props);
		  
		  this.showSideBar   = this.showSideBar.bind(this);
		  this.hideSideBar   = this.hideSideBar.bind(this);
		  this.toogleSubmenu = this.toogleSubmenu.bind(this);
		  
		  this.logout      = this.logout.bind(this);
		  this.state = {...INITIAL_STATE};
		  
	  }
	  
	  showSideBar(e){
		  e.preventDefault();
		  if(this.state.cl){
			  this.setState({cl:""});
		  }else{
			  this.setState({cl:"open"});
		  }
	  }
	  hideSideBar(e){
		  this.setState({cl:""});
	  }
	  
	  logout(e,client){	  

		  e.preventDefault();
		  client.resetStore();

		  localStorage.removeItem("token");
		  localStorage.removeItem("loggedUser");
		  localStorage.removeItem("loginPlace");

		  window.location.reload();

	  }
	  
	  toogleSubmenu(e,menu,prevent){
		  		  
		  if(prevent)e.preventDefault();
			  
		  var arr = this.state.showSubmenu.map(function(item,index){
			  if(index !== menu)return {...item,show:false};
			  else return {...item,show:!item.show};
		  });
		  
		  if(prevent)this.setState({...this.state,showSubmenu:arr});
		  else this.setState({...this.state,showSubmenu:arr,cl:''});
	  }
	  
	  handleClickOutside = evt => {
		  this.setState(INITIAL_STATE);
	  };
	
	  render() {

		const {cl} = this.state;
		const authUser = localStorage.getItem('loggedUser');
						  
	    return (
	    	<div id="header" className="mb-1">
	    		<ul className="d-flex m-0 p-0 main">
	    		    <li><button tabIndex="0" onClick={this.showSideBar} ><img src={menu} /></button></li>
	    		    <li className="logo"><img alt="wealth stability" src={logo} /></li>
	    			<li className="user ml-auto">
						<NavLink to="/admin/settings/change-password">
							{authUser}
						</NavLink></li>
	    			<li className="">
						<ApolloConsumer>
							{client => (
								<button onClick={(e) => this.logout(e,client)}><img src={door} /></button>
							)}
								
						</ApolloConsumer>
					</li>
	    		</ul>
	    		<div className={"sideBar " + cl}>
	    			<ul>
	    				
						<li><NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/admin/customer"><img src={user} /> <span>Zákaznící</span></NavLink></li>
						<li><NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/admin/orders"><img src={orders} /> <span>Objednávky</span></NavLink></li>
	    				
	        			<li>
	        				<NavLink onClick={(e) => this.toogleSubmenu(e,3,true)} activeClassName="active" to="/admin/settings"><img src={settings} /> <span>Nastavení</span></NavLink>
	        				<ul className={(this.state.showSubmenu[3].show) ? '' : 'hidden' }>
	        				    <li className="last"><NavLink onClick={this.handleClickOutside} activeClassName="active" to="/admin/settings/change-password"> Změna hesla </NavLink></li>
	        				</ul>
	        			</li>
	        			<li>
						    <ApolloConsumer>
								{client => (
									<button onClick={(e) => this.logout(e,client)}><img src={doorLighter} /> <span>Odhlásit se</span></button>
								)}
							</ApolloConsumer>
						</li>

	    			</ul>
	    		</div>
	    	</div>
	    );
	  }
  
}


export default withRouter(onClickOutside(Header));
