/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

/**
 * vložení a editace zákazníka
 */
export const ADD_EDIT_CUSTOMER = gql`
    mutation AddEditCustomer(
        $customerID:ID,
        $sapCode: String,
        $name: String,
        $surname: String,
        $company: String,
        $email: String,
        $tel: String,
        $street: String,
        $streetNumber: String,
        $city: String,
        $zip: String,
        $countryID:ID,
        $dic: String,
        $ic: String,
        $description: String
    ){
        addEditCustomer(
            customerID:          $customerID,
            sapCode:             $sapCode,
            name:                $name,
            surname:             $surname,
            company:             $company,
            email:               $email,
            tel:                 $tel,
            street:              $street,
            streetNumber:        $streetNumber,
            city:                $city,
            zip:                 $zip,
            countryID:           $countryID,
            dic:                 $dic,
            ic:                  $ic,
            description:         $description
        ){
            customerID
            sapCode
            name
            surname
            company
            email
            tel
            street
            streetNumber
            city
            zip
            countryID
            dic
            ic
            description         
        }
    }
`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER_BY_EMAIL = gql`
    query CustomerByEmail($email: String!,$customerID:ID!){
        customerByEmail(email:$email,customerID:$customerID){
            customerID
        }
    }
`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER = gql`
    query Customer($customerID: ID!){
        customer(customerID:$customerID){
            customerID
            sapCode
            name
            surname
            company
            email
            tel
            street
            streetNumber
            city
            zip
            countryID
            dic
            ic
            description
        }
        allCountries{
            countryID
            title
            defaultSelected
        }
        
    }
`;

/**
 *  Seznam veškerých zákazníků
 */
export const GET_CUSTOMERS = gql`
    query AllCustomers($offset:Int, $limit: Int,$text:String){
        allCustomers(offset:$offset,limit:$limit,text:$text){
            sapCode
            customerID
            name
            surname
            company
            email
            tel
        }
        
    }
`;

export const SEARCH_CUSTOMERS = gql`
    query SearchCustomers($text:String){
        searchCustomers(text:$text){
            customerID
            sapCode
            name
            surname
            company
            email
            tel
            street
            streetNumber
            city
            zip
            countryID
            dic
            ic
        }
        
    }
`;

/**
 * Smazání zákazníka.
 */
export const DELETE_CUSTOMER = gql`
    mutation deleteCustomers($customerID:ID!) {
        deleteCustomers(customerID:$customerID)
    }
`;

/**
 * Import uživatelů
 */
export const IMPORT_CUSTOMERS = gql`
    mutation importCustomers {
        importCustomers
    }
`;
