/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import {Query,Mutation,withApollo} from 'react-apollo';
import {IMPORT_CUSTOMERS} from '../Queries/customer';


class CustomerImport extends Component{

    constructor(props){
       super(props);

       this.importCustomers = this.importCustomers.bind(this);
    }

    importCustomers(){
        this.props.client.mutate({
            mutation:IMPORT_CUSTOMERS
        })
    }

    render(){

        return(

            <div id="settings" className="whole-container" >
                <button onClick={() => this.importCustomers()}>Importovat</button>
            </div>
        );

    }

}

export default withApollo(CustomerImport);