/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import {GET_ORDER} from '../Queries/order';
import Loading from '../../../../GlobalComponents/Loading';
import moment from "moment";
import Modal from 'react-modal';
import {SERVER_URL} from '../../../../Config/index';

const OrderDetail = (props) => {
        

    const [orderCount,setOrderCount]        = useState(0);
    

    const {loading, error, data, refetch}   = useQuery(GET_ORDER,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            orderID:props.orderID
        },
        onCompleted(data){
            setOrderCount(data.order.orderForAdminCount);
        }
    });
    
    
    return(
    	<Modal
	        className="Modal__Bootstrap modal-dialog modal-xl"
	        closeTimeoutMS={150}
	        isOpen={true}
	        onRequestClose={() => props.callback(false)}
	    >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title bold">Objednávka z {data && data.order && moment(data.order.dateAdd).format("DD.MM.YYYY")}</h4>
                    <button type="button" className="close" onClick={() => props.callback(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body p-0" style={{backgroundColor:"#eff1f2"}}>	 
                    {!loading ?  
                        <div id="client-content" className="p-3">  
                            <div id="order">      
                                <section>
                                    <div className="header">
                                        <div className="sequence">1</div>
                                        Údaje
                                    </div>
                                    <div className="content">

                                        <div className="row">

                                            <div className="col-12 col-lg-3 company">
                                                <div className="form-group">
                                                    <label>Název firmy <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.company}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-9">

                                                <div className="address">
                                                    <div className="row">

                                                        <div className="col-12 address-header">
                                                            <strong>Sídlo</strong>
                                                        </div>
                                                    
                                                        <div className="col-12 col-sm-8 col-md-4">
                                                            <label>Ulice <span>*</span></label>
                                                            <div className="bold">
                                                                {data.order.street}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-4 col-md-2">
                                                            <label>Č. popisné <span>*</span></label>
                                                            <div className="bold">
                                                                {data.order.streetNumber}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-md-4">
                                                            <label>Město <span>*</span></label>
                                                            <div className="bold">
                                                                {data.order.city}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-4 col-md-2">
                                                            <label>PSČ <span>*</span></label>
                                                            <div className="bold">
                                                                {data.order.zip}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="form-group">
                                                    <label>DIČ <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.dic}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3 ">
                                                <div className="form-group">
                                                    <label>Jméno objednávajícího <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.name} {data.order.surname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <div className="form-group">
                                                    <label>Telefon <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.tel}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </div>

                                </section>

                                <section>
                                    <div className="header">
                                        <div className="sequence">2</div>
                                        Zboží
                                    </div>
                                    <div className="content">

                                        <div className="row">
                                            <div className="col-12 col-lg-3">
                                                <div className="form-group">
                                                    <label>Způsob zadání <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.offerNumber ?
                                                            <>Dle nabídky</>
                                                            :
                                                            <>
                                                                {data.order.orderGoods && data.order.orderGoods.length > 0 ? 
                                                                    <>Vlastní specifikace zboží</>
                                                                    :
                                                                    <>Vlastní specifikace v příloze</>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-9">

                                                {data.order.offerNumber ?
                                                
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label>Číslo nabídky <span>*</span></label>
                                                                <div className="bold">
                                                                    {data.order.offerNumber} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label>Interní číslo objednávky </label>
                                                                <div className="bold">
                                                                    {data.order.intOrderNumber}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <>
                                                        {data.order.orderGoods && data.order.orderGoods.length > 0 ? 
                                                            <div className="row goods">

                                                                {data.order.orderGoods.map((item,index) => (
                                                                    <Fragment key={index}>
                                                                        <div className="col-8 col-sm-5 col-md-5">
                                                                            <div>
                                                                                <label>Kód zboží Daikin <span>*</span></label>                                                                        
                                                                                <div key={index} className="form-group bold">
                                                                                    {item.daikinCode}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4 col-sm-3 col-md-2">
                                                                            <div>
                                                                                <label>Množství <span>*</span></label>                                                                        
                                                                                <div className="form-group bold">
                                                                                    {item.count}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-4 col-md-5">
                                                                            <div>
                                                                                <label>Interní č. objednávky </label>

                                                                                <div className="form-group bold">
                                                                                    {item.orderNumber} 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                ))}
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label>Přílohy <span>*</span></label>
                                                                        <ul>
                                                                            {data.order.orderFiles.map((item,index) => (
                                                                                <li key={index}>
                                                                                    <a href={SERVER_URL + "/Public/Upload/" + item.file} target="_blank">{item.file}</a>                                                                            
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>   
                                                }

                                                
                                            </div>
                                            
                                        </div>

                                    </div>

                                </section>

                                <section>
                                    <div className="header">
                                        <div className="sequence">3</div>
                                        Termín
                                    </div>
                                    <div className="content">

                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <label>Požadovaný termín dodání <span>*</span></label>
                                                    <div className="relative bold">
                                                        {moment(data.order.deliveryDate).format("DD.MM.YYYY")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 col-lg-9 d-flex align-items-center">

                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" checked disabled className="custom-control-input" id="checkTerm" />
                                                    <label className="custom-control-label blue-color" htmlFor="checkTerm">Zákazník potvrzuje zajištění převzetí zboží ve výše uvedený den /  změna termínu dodání je možná při avizaci 48 hodin před dodávkou</label>
                                                </div>

                                                
                                            </div>
                                            
                                            
                                        </div>

                                    </div>

                                </section>

                                <section>
                                    <div className="header">
                                        <div className="sequence">4</div>
                                        Místo
                                    </div>
                                    <div className="content">

                                        <div className="row">
                                            <div className="col-12 col-lg-3">
                                                <div className="form-group">
                                                    <label>Místo dodání <span>*</span></label>
                                                    <div className="bold">
                                                        {data.order.deliveryStreet == "" ? 
                                                            <>Na fakturační adresu</>
                                                            :
                                                            <>Na jinou adresu</>
                                                        }
                                                    </div>
                                                </div>
                                            </div>     
                                            <div className="col-12 col-lg-9">
                                                <div className="col-12 col-md-8">
                                                    <div className="form-group">
                                                        <label>Adresa</label>                                                        
                                                        {data.order.deliveryStreet == "" ?                                                             
                                                            <div className="form-group bold">{data.order.street} {data.order.streetNumber}, {data.order.city} {data.order.zip}</div>
                                                            :
                                                            <div className="form-group bold">{data.order.deliveryStreet} {data.order.deliveryStreetNumber}, {data.order.deliveryCity} {data.order.deliveryZip}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>                               
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <div className="header">
                                        <div className="sequence">5</div>
                                        Poznámka
                                    </div>
                                    <div className="content">
                                        <label>Poznámka</label>
                                        <div className="bold">
                                            {data.order.note}
                                        </div>                                
                                    </div>                            
                                </section>
                            </div>	
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </div>
        </Modal>
        
    );

}

export default withApollo(OrderDetail);