/*
    Přidání potahu
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_DELIVERY_ADDRESS} from '../Queries/deliveryAddress';
import DeliveryAddressLib from '../Library/deliveryAddress';
import Notification from '../../../../Library/notification';
import Helper from '../../../../Library/helper';

const INITIAL_STATE = {
    apolloError:"",
    selectedDeliveryAddressID:null,
    allCountries:[],
    formData:{
        deliveryAddressID:0,
        name:"",
        surname:"",
        company:"",
        tel:"",
        street:"",
        streetNumber:"",
        city:"",
        zip:"",
        countryID:60,
        
    }
}

class AddEditDeliveryAddress extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.deliveryAddress = new DeliveryAddressLib(this); 
        this.deliveryAddress.getData();
        
    }
    
    render(){

        const {selectedDeliveryAddressID,apolloError,allCountries} = this.state;
        const {customerID} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_DELIVERY_ADDRESS}
                update = {async (cache, response) => {

                    this.deliveryAddress.updateDeliveryAddressList(cache,response,selectedDeliveryAddressID,customerID);

                    let notify = new Notification();

                    if(selectedDeliveryAddressID && selectedDeliveryAddressID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {
                    this.props.openCloseModal(false);   
                }}
                
            >
            {
                (addDeliveryAddress,{data,loading,error}) => {

                    const {
                        name,surname,company,tel,street,streetNumber,city,zip,countryID
                    } = this.state.formData;

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedDeliveryAddressID && selectedDeliveryAddressID != 0 ? "Úprava doručovací adresy" : "Přidání doručovací adresy")}</h4>
                                    <button type="button" className="close" onClick={() => this.props.openCloseModal(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    
                                    <div>
                                        {!loading ?
                                            <Fragment>
                                                {error || apolloError ?
                                                    <Error text={error || apolloError} />
                                                :
                                                    <div className="row">

                                                        {false ?  
                                                            <Fragment>                                                   
                                                                <div className="col-12 col-sm-4">
                                                                    <label className="input-label">Jméno</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="name" value={name} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-4">
                                                                    <label className="input-label">Příjmení</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="surname" value={surname} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                                    </div>
                                                                </div>
                                                            </Fragment>   

                                                            :
                                                            <Fragment>
                                                                <div className="col-6">
                                                                    <label className="input-label">Název stavby / firmy</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="company" value={company} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="input-label">Tel.</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="tel" value={tel} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }

                                                        
                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">Ulice</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="street" value={street} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <label className="input-label">Č. popisné</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="streetNumber" value={streetNumber} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-2">
                                                            <label className="input-label">PSČ</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="zip" value={zip} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">Město</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="city" value={city} onChange={(e) => this.deliveryAddress.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        {false ? 
                                                            <div className="col-12 col-sm-4">
                                                                <label className="input-label">Stát</label>
                                                                <div className="form-group">
                                                                    <select className="form-control" name="countryID" value={countryID} onChange={(e) => this.deliveryAddress.formHandle(e)}>
                                                                        <option value="0"> --- vyberte stát --- </option>  
                                                                        {allCountries && allCountries.map((item,index) => (
                                                                            <option key={index} value={item.countryID}> {item.title} </option> 
                                                                        ))}  
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-12 col-sm-4"></div>

                                                        }


                                                    </div>
                                                }
                                            </Fragment>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.deliveryAddress.addDeliveryAddress(addDeliveryAddress,customerID)}>{(selectedDeliveryAddressID && selectedDeliveryAddressID != 0 ? "Upavit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditDeliveryAddress);