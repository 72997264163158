/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací zákazníků
 */

import {GET_CUSTOMER,GET_CUSTOMERS,GET_CUSTOMER_BY_EMAIL} from '../Queries/customer.js';
import Notification from '../../../../Library/notification';
import Helper from '../../../../Library/helper';

class Customer {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
     */

    async getData(){

        var customerID = 0;
        if(this.parent.props.selectedCustomerID)customerID = this.parent.props.selectedCustomerID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_CUSTOMER,
            errorPolicy:"all",
            variables:{customerID:customerID}, 
            fetchPolicy: 'network-only'
        });

        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }

        if(data.data){

            var allCountries = [];
            var countryID = 0;
            var deliveryCountryID = 0;

            if(data.data.allCountries && data.data.allCountries.length > 0){
                allCountries =  data.data.allCountries;
                for(let val of allCountries){
                    if(val.defaultSelected == 1){
                        countryID = val.countryID;
                        deliveryCountryID = val.countryID;
                    }
                }
            }
            
            if(data.data.customer){
                
                this.parent.setState({
                    apolloError:"",selectedCustomerID:customerID, allCountries,
                    formData:{...this.parent.state.formData,
                        customerID:          data.data.customer.customerID,
                        sapCode:             data.data.customer.sapCode,
                        name:                data.data.customer.name,
                        surname:             data.data.customer.surname,
                        company:             data.data.customer.company,
                        email:               data.data.customer.email,
                        tel:                 data.data.customer.tel,
                        street:              data.data.customer.street,
                        streetNumber:        data.data.customer.streetNumber,
                        city:                data.data.customer.city,
                        zip:                 data.data.customer.zip,
                        countryID:           data.data.customer.countryID,
                        dic:                 data.data.customer.dic,
                        ic:                  data.data.customer.ic,
                        description:         data.data.customer.description,
  
                    }
                });

            }else{
                this.parent.setState({ apolloError:"", allCountries,formData:{...this.parent.state.formData, countryID, deliveryCountryID }});
            }
        }
    }

    /**
     * Přidání nebo úprava nového zákazníka
     * @param {*} addCustomer funkce z apollo, pomocí které se posílají data na server
     */
    async addCustomer(addCustomer){

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.name != "" && data.surname != "" && data.company != ""){         
            if(data.street != ""){
                if(data.streetNumber != ""){
                    if(data.city != ""){
                        if(data.zip != ""){
                            if(data.countryID != 0){
                                if(data.dic != ""){
                                    if(data.tel != ""){
                                    
                                        const helper = new Helper();
                                        if(data.email != "" && helper.validateEmail(data.email)){

                                            var custEmail = await this.parent.props.client.query({ 
                                                query: GET_CUSTOMER_BY_EMAIL,
                                                errorPolicy:"all",
                                                variables:{email:data.email, customerID:data.customerID}, 
                                                fetchPolicy: 'network-only'
                                            });
                                    
                                            if(custEmail.errors){
                                                this.parent.setState({apolloError:custEmail.errors[0].message});
                                            }else{
                                                if(custEmail.data && custEmail.data.customerByEmail){
                                                    notify.setNotification(null,'Tento email už používá jiný zákazník',false,true,this.parent.props.client); 
                                                }else{
                                                    addCustomer({
                                                        variables:data
                                                    });
                                                }
                                            }

                                        }else{
                                            notify.setNotification(null,'Email není ve správném tvaru.',false,true,this.parent.props.client); 
                                        }

                                    }else{
                                        notify.setNotification(null,'Nevyplnili jste telefon.',false,true,this.parent.props.client); 
                                    }
                                    
                                }else{
                                    notify.setNotification(null,'Nevyplnili jste DIČ.',false,true,this.parent.props.client); 
                                }
                            }else{
                                notify.setNotification(null,'Nevyplnili jste stát.',false,true,this.parent.props.client); 
                            }
                        }else{
                            notify.setNotification(null,'Nevyplnili jste PSČ.',false,true,this.parent.props.client); 
                        }
                    }else{
                        notify.setNotification(null,'Nevyplnili jste město.',false,true,this.parent.props.client); 
                    }
                }else{
                    notify.setNotification(null,'Nevyplnili jste číslo popisné.',false,true,this.parent.props.client); 
                }
            }else{
                notify.setNotification(null,'Nevyplnili jste ulici.',false,true,this.parent.props.client); 
            }
           
        }else{
            notify.setNotification(null,'Nevyplnili jste jméno nebo příjmení nebo společnost.',false,true,this.parent.props.client);
        }

    }

    /**
     * funckce pro zobrazování tabů
     */
    showTab(e,tab){

        e.preventDefault();
        this.parent.setState({selectedTab:tab}); 
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {*} cache apollo cahe v které jsou oložena data
     * @param {*} response položka, která byla vložena nebo upravována
     */

    updateCustomerList(cache,response,selectedCustomerID,customerListVariables){

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS, variables:customerListVariables });

        if(!(selectedCustomerID && selectedCustomerID != 0)){

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: [response.data.addEditCustomer,...allCustomers]
                } 
            });

        }else{

            var arr = [...allCustomers];
            
            arr.forEach((item,index) => {
                if(item.customerID == response.data.addEditCustomer.customerID){
                    arr[index] = response.data.addEditCustomer;
                }

            });

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: arr
                } 
            });
        }
    }

    /**
     * Update seznamu zákazníků po smazání vybraných zákazníků
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     */
    
    updateAfterDeleteCustomer(cache, response,customerListVariables){

        var resp = response.data.deleteCustomers.split(",");

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS,variables:customerListVariables });
        var arr = [...allCustomers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.customerID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
        
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS,
            variables:customerListVariables,
            data:{
                allCustomers: [...arr]
            } 
        });

    }

    /**
     * pomocná funkce pro editor, která ukládá vložený text do popisu
     * @param {*} data text pro popis
     */

    setDescription(data){
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,["description"]: data}});
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default Customer;