/**
 *  komponenta pro štítky pro produkty
 */

import React,{ Fragment, useState,useRef } from 'react';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Notification from '../../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_ORDERS} from '../Queries/order';
import Loading from '../../../../GlobalComponents/Loading';
import NoItems from '../../../../GlobalComponents/NoItems';
import OrderLib from '../Library/order';
import showDetail from '../../../../Public/Images/eye-open.svg';
import calendar from '../../../../Public/Images/calendar.svg';
import moment from "moment";
import Helper from '../../../../Library/helper';
import Pagination from "react-js-pagination";
import SearchInput from '../../../../GlobalComponents/SearchInput';
import OrderDetail from '../../../Admin/Order/Components/OrderDetail';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

const Orders = (props) => {
        
    const dateFromRef                               = useRef();
    const dateToRef                                 = useRef();
    const [selectedOrders,setSelectedOrders]        = useState([]);
    const [showDeleteNotifi,setShowDeleteNotifi]    = useState(false);
    const [showOrderDetail,setShowOrderDetail] 		= useState(false);
    const [selectedOrderID,setSelectedOrderID]      = useState(0);

    const [orderCount,setOrderCount]        = useState(0);
    const [selectedPage,setSelectedPage]    = useState(1);
    const [searchText,setSearchText]        = useState("");
    const [dateFrom,setDateFrom]            = useState(null);
    const [dateTo,setDateTo]                = useState(null);
    

    const [filterParams,setFilterParams] = useState({
        onPage:5,
        offset:0,
        searchQuery:"",
        orderBy:"",
        orderDirection:"DESC",
        dateFrom:"",
        dateTo:"",
    });


    const {loading, error:getError, data:orderListData, refetch}   = useQuery(GET_ORDERS,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            filterParams
        },
        onCompleted(data){
            setOrderCount(data.orderForClientCount);
        }
    });

    const selectPage = (page) => {
        setSelectedPage(page);
        setFilterParams({...filterParams,offset:(page - 1) * filterParams.onPage});
    }

    const showDelNotifi = () => {

        if(selectedOrders.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,"Nevybrali jste žádné položky ke smazání",false,true,props.client);
        }
    }

    const setOrder = (orderBy) => {
        if(orderBy == filterParams.orderBy){
            setFilterParams({...filterParams,offset:0,orderDirection: filterParams.orderDirection == "ASC" ?  "DESC" : "ASC"});
        }else{
            setFilterParams({...filterParams,offset:0,orderBy,orderDirection:"ASC"});
        }
        setSelectedPage(1);
    }
    const searchCompany = (searchQuery) => {
        setSearchText(searchQuery);
        setSelectedPage(1);
        setFilterParams({...filterParams,searchQuery,offset:0});
    }
    const setDateFromInput = (dateFrom) => {
        setSelectedPage(1);
        setDateFrom(dateFrom);
        setFilterParams({...filterParams,dateFrom:moment(dateFrom).format("YYYY-MM-DD"),offset:0});
    }
    const setDateToInput = (dateTo) => {
        setSelectedPage(1);
        setDateTo(dateTo);
        setFilterParams({...filterParams,dateTo:moment(dateTo).format("YYYY-MM-DD"),offset:0});
    }
    const clearFilter = (dateTo) => {
        setSelectedPage(1);
        setDateFrom(null);
        setDateTo(null);
        setSearchText("");
        setFilterParams({
            onPage:20,
            offset:0,
            searchQuery:"",
            orderBy:"",
            orderDirection:"DESC",
            dateFrom:"",
            dateTo:""
        });
    }
    
    const openCloseOrderDetailModal = (open,orderID) => {
        setSelectedOrderID(orderID);
        setShowOrderDetail(open);
    }
    
    
    return(

        <div id="order" >
            <h2>Předchozí objednávky</h2>
            <div className="filters-wrapper">
                <div className="filters mb-5">
                    <div className="d-flex align-items-center">
                        <label className="m-0 bold mr-3">Zobrazit objednávky</label>
                        <label className="bold m-0 mr-2">od</label>
                        <div className="date-input mr-3">                                                        
                            <DatePicker ref={dateFromRef} onKeyDown={e => e.preventDefault()} placeholderText="DD.MM.YYYY" autoComplete="off" className="form-control w-100" dateFormat="yyyy-MM-dd" locale={"cs"} selected={dateFrom} onChange={(date) => setDateFromInput(date)} />
                            <img onClick={() => dateFromRef.current.handleFocus()} src={calendar} />
                        </div>
                        <label className="bold m-0 mr-2">do</label>
                        <div className="date-input">                                                        
                            <DatePicker  ref={dateToRef} onKeyDown={e => e.preventDefault()} placeholderText="DD.MM.YYYY" autoComplete="off" className="form-control w-100" dateFormat="yyyy-MM-dd" locale={"cs"} selected={dateTo} onChange={(date) => setDateToInput(date)} />
                            <img onClick={() => dateToRef.current.handleFocus()} src={calendar} />
                        </div>
                        <div className="ml-4 filter-text" onClick={() => clearFilter()}>Zrušit filtr</div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-sm one">
                    <div className="data-list">
                        
                        <div className="data-list-item header">

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center header-item justify-content-center">
                                                <div className="cont pointer" onClick={() => setOrder("O.dateAdd")}>
                                                    Datum 
                                                    <div className="arrows">
                                                        <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.dateAdd" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.dateAdd" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center header-item">
                                                <div className="cont pointer" onClick={() => setOrder("O.company")}>
                                                    Firma 
                                                    <div className="arrows">
                                                        <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.company" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.company" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center header-item">
                                                <div className="cont pointer" onClick={() => setOrder("O.name")}>
                                                    Objednávající 
                                                    <div className="arrows">
                                                        <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.name" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.name" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center header-item">
                                                <div className="cont">
                                                    Způsob zadání 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center header-item ">
                                                <div className="cont pointer" onClick={() => setOrder("O.deliveryDate")}>
                                                    <div className="no-wrap">Termín dodání</div> 
                                                    <div className="arrows">
                                                        <div className={"triangle-to-top mb-2 d-block " + (filterParams.orderBy == "O.deliveryDate" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 d-block " + (filterParams.orderBy == "O.deliveryDate" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center header-item">
                                                <div className="cont">
                                                    Místo dodání 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-end static small align-items-center header-item">
                                                <div className="cont">Možnosti</div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderListData && orderListData.allOrdersForClient && orderListData.allOrdersForClient.map((item,index) => {

                                        var checked = false;
                                        for(let i in selectedOrders){
                                            if(selectedOrders[i] == item.orderID)checked = true;
                                        }
                                                                                                        
                                        return (
                                            <tr key={index} className="body-row">
                                                <td className="text-center cont">{moment(item.dateAdd).format("DD.MM.YYYY")}</td>
                                                <td className="text-center cont">{item.company}</td>
                                                <td className="text-center cont">{item.name} {item.surname}</td>
                                                <td className="text-center cont">
                                                    {item.offerNumber ?
                                                        <>Nabídka ({item.offerNumber})</>
                                                        :
                                                        <>Vlastní specifikace zboží</>
                                                    }
                                                </td>
                                                <td className="text-center cont">{moment(item.deliveryDate).format("DD.MM.YYYY")}</td>
                                                {item.deliveryStreet == "" ? 
                                                    <td className="text-center cont">Na fakturační adresu</td>
                                                    :
                                                    <td className="text-center cont">{item.deliveryStreet} {item.deliveryStreetNumber}<br/>{item.deliveryCity}, {item.deliveryZip}</td>
                                                }
                                                <td className="text-right static small cont">
                                                    <button className="btn btn-blue" onClick={() => openCloseOrderDetailModal(true,item.orderID)}>Zobrazit</button>
                                                </td>
                                            </tr>                                                
                                        )

                                    })} 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {!loading ? 
                        <Fragment>
                            {orderListData && orderListData.allOrdersForClient && orderListData.allOrdersForClient.length == 0 ?
                                <NoItems text={"Nebyly vloženy žádné objednávky"} />
                                :
                                <div className="d-flex align-items-center justify-content-center p-5">
                                    <Pagination
                                        activePage={selectedPage}
                                        itemsCountPerPage={filterParams.onPage}
                                        totalItemsCount={orderCount}
                                        pageRangeDisplayed={10}
                                        hideFirstLastPages={false}
                                        onChange={(number) => selectPage(number)}
                                        activeLinkclassName="selected"
                                    />
                                </div>
                            }
                        </Fragment>
                        :                                    
                        <Loading />                                    
                    }  
                </div>
                {showOrderDetail && <OrderDetail orderID={selectedOrderID} callback={(state) => setShowOrderDetail(state)} /> }
            </div> 
        </div> 	
            	
        
    );

}

export default withApollo(Orders);