/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component } from 'react';
import { NavLink } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { withRouter } from 'react-router-dom';
import { ApolloConsumer} from 'react-apollo';
import logo from '../../Public/Images/logo.png';
import { GET_CUTOMER_DATA } from '../../Modules/Client/Order/Queries/order';

import {withApollo} from 'react-apollo';

const INITIAL_STATE = {
	cl:"",
	userData:null,
	showSubmenu:[
	  {menuID:1,show:false},
	  {menuID:2,show:false},
	  {menuID:3,show:false},
	  {menuID:4,show:false},
	  {menuID:5,show:false}
	]
}

class Header extends Component {
	
	  constructor(props){
		  super(props);
		  
		  this.showSideBar   = this.showSideBar.bind(this);
		  this.hideSideBar   = this.hideSideBar.bind(this);
		  this.toogleSubmenu = this.toogleSubmenu.bind(this);
		  
		  this.logout      = this.logout.bind(this);
		  this.state = {...INITIAL_STATE};
		  
	  }
	  

	  showSideBar(e){
		  e.preventDefault();
		  if(this.state.cl){
			  this.setState({cl:""});
		  }else{
			  this.setState({cl:"open"});
		  }
	  }
	  hideSideBar(e){
		  this.setState({cl:""});
	  }
	  
	  logout(e,client){	  

		  e.preventDefault();
		  client.resetStore();

		  localStorage.removeItem("token");
		  localStorage.removeItem("loggedUser");

		  window.location.reload();

	  }
	  
	  toogleSubmenu(e,menu,prevent){
		  		  
		  if(prevent)e.preventDefault();
			  
		  var arr = this.state.showSubmenu.map(function(item,index){
			  if(index !== menu)return {...item,show:false};
			  else return {...item,show:!item.show};
		  });
		  
		  if(prevent)this.setState({...this.state,showSubmenu:arr});
		  else this.setState({...this.state,showSubmenu:arr,cl:''});
	  }
	  
	  handleClickOutside = evt => {
		  this.setState(INITIAL_STATE);
	  };
	
	  render() {

		const {cl,userData} = this.state;
		const authUser = localStorage.getItem('loggedUser');
			console.log(authUser);			  
	    return (
			<>
	    	<header id="web">
				<div><img src={logo} /></div>
				<h1>Objednávkový systém</h1>
			</header>
				{authUser && authUser != "" &&
					<div className="client-menu">	    				
						<NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/objednavka">Nová objednávka</NavLink>
						<NavLink onClick={(e) => this.toogleSubmenu(e,0)} activeClassName="active" to="/predchozi-objednavky">Předchozí objednávky</NavLink>
					</div>
				}
			</>
	    );
	  }
  
}


export default withRouter(withApollo(Header));
