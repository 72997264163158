import {GET_CUTOMER_DATA,DELIVERY_ADDRESSES} from '../Queries/order';
import {MAX_ATTACHMENT_SIZE_MB,MAX_ATTACHMENT_FILE_COUNT} from '../../../../Config/index';

class Order{

    constructor(parent){
        this.parent = parent;
    }

    async getData(){
        var data = await this.parent.props.client.query({
            query:GET_CUTOMER_DATA
        });

        if(data.data.customerData){

            const {name,surname,company,street,streetNumber,countryID,city,zip,tel,dic,customerID,email} = data.data.customerData;

            this.parent.setState({formData:{...this.parent.state.formData,
                customerID,email,name,surname,company,street,streetNumber,countryID,city,zip,tel,dic
            }});

        }
    }

    addOrder(addOrder){

        const {inputMethod,deliveryPlace, formData} = this.parent.state;
        var {deliveryAddressID,customerID,email,name,surname,company,street,streetNumber,city,zip,dic,ic,tel,intOrderNumber,offerNumber,deliveryDate,note,goods,files,checkTerm} = formData;
        
        if(inputMethod != 3)files = [];

        if(company != ""){
            if(street != ""){
                if(streetNumber != ""){
                    if(city != ""){
                        if(zip != ""){
                            if(dic != ""){
                                if(tel != ""){
                                    if(name != ""){
                                        if(surname != ""){

                                            if((inputMethod == 1 && offerNumber != "") || inputMethod != 1){

                                                if((inputMethod == 3 && files && files.length > 0) || inputMethod != 3){

                                                    if(deliveryDate != ""){
                                                        if(checkTerm){

                                                            if(deliveryAddressID != 0 && deliveryPlace == 2 || deliveryPlace == 1){

                                                                var goodsData = [];
                                                                var goodsCountCheck = true;
                                                                var goodsCodeCheck = true;
                                                                    
                                                                if(inputMethod == 2){

                                                                    goodsData = goods;
                                                                    for(let val of goodsData){
                                                                        if(val.daikinCode == "")goodsCodeCheck = false;
                                                                        else if(val.count == "")goodsCountCheck = false;
                                                                        else val.count = parseInt(val.count);
                                                                    }
                                                                }

                                                                if(goodsCodeCheck){

                                                                    if(goodsCountCheck){
            
                                                                        addOrder({
                                                                            variables:{
                                                                                customerID,
                                                                                email,
                                                                                deliveryAddressID,
                                                                                name,
                                                                                surname,
                                                                                company,
                                                                                street,
                                                                                streetNumber,
                                                                                city,
                                                                                zip,
                                                                                dic,
                                                                                ic,
                                                                                tel,
                                                                                intOrderNumber,
                                                                                offerNumber,
                                                                                deliveryDate,
                                                                                note,
                                                                                goods:goodsData,
                                                                                files
                                                                            }
                                                                        });

                                                                    }else{
                                                                        this.parent.setState({ err:"Nevyplnili jste u zboží množství."},() => {
                                                                            setTimeout(() => this.parent.setState({ err:""}),4000);
                                                                        });
                                                                    }
                                                                    
                                                                }else{
                                                                    this.parent.setState({ err:"Nevyplnili jste u zboží kód Daikin."},() => {
                                                                        setTimeout(() => this.parent.setState({ err:""}),4000);
                                                                    });
                                                                }
                                                                
                                                            }else{
                                                                this.parent.setState({ err:"Nevyplnili jste doručovací adresu."},() => {
                                                                    setTimeout(() => this.parent.setState({ err:""}),4000);
                                                                });
                                                            }
                                                            
                                                        }else{
                                                            this.parent.setState({ err:"Nepotvrdili jste zajištění převzení zboží ve zvoleném termínu."},() => {
                                                                setTimeout(() => this.parent.setState({ err:""}),4000);
                                                            });
                                                        }

                                                    }else{
                                                        this.parent.setState({ err:"Nevyplnili jste termín dodání."},() => {
                                                            setTimeout(() => this.parent.setState({ err:""}),4000);
                                                        });
                                                    }
                                                }else{
                                                    this.parent.setState({ err:"Nepřidali jste přílohu."},() => {
                                                        setTimeout(() => this.parent.setState({ err:""}),4000);
                                                    });
                                                }
                                                
                                            }else{
                                                this.parent.setState({ err:"Nevyplnili jste číslo nabídky."},() => {
                                                    setTimeout(() => this.parent.setState({ err:""}),4000);
                                                });
                                            }
                                            
                                        }else{
                                            this.parent.setState({ err:"Nevyplnili jste příjmení objednávajícího."},() => {
                                                setTimeout(() => this.parent.setState({ err:""}),4000);
                                            });
                                        }
                                        
                                    }else{
                                        this.parent.setState({ err:"Nevyplnili jste jméno objednávajícího."},() => {
                                            setTimeout(() => this.parent.setState({ err:""}),4000);
                                        });
                                    }

                                }else{
                                    this.parent.setState({ err:"Nevyplnili jste telefon."},() => {
                                        setTimeout(() => this.parent.setState({ err:""}),4000);
                                    });
                                }

                            }else{
                                this.parent.setState({ err:"Nevyplnili jste DIČ."},() => {
                                    setTimeout(() => this.parent.setState({ err:""}),4000);
                                });
                            }

                        }else{
                            this.parent.setState({ err:"Nevyplnili jste PSČ."},() => {
                                setTimeout(() => this.parent.setState({ err:""}),4000);
                            });
                        }
                    }else{
                        this.parent.setState({ err:"Nevyplnili jste město."},() => {
                            setTimeout(() => this.parent.setState({ err:""}),4000);
                        });
                    }
                }else{
                    this.parent.setState({ err:"Nevyplnili jste číslo popisné."},() => {
                        setTimeout(() => this.parent.setState({ err:""}),4000);
                    });
                }
            }else{
                this.parent.setState({ err:"Nevyplnili jste ulici."},() => {
                    setTimeout(() => this.parent.setState({ err:""}),4000);
                });
            }
        }else{
            this.parent.setState({ err:"Nevyplnili jste název firmy."},() => {
                setTimeout(() => this.parent.setState({ err:""}),4000);
            });
        }
    }

    setDeliveryAddress(e){
        this.parent.setState({addEditDeliveryAddress : 0,deleteDeliveryAddress:false,formData:{...this.parent.state.formData,
            deliveryAddressID:e.target.value,
            deliveryCompany:"",
            deliveryTel:"",
            deliveryStreet:"",
            deliveryStreetNumber:"",
            deliveryCity:"",
            deliveryZip:"",
        }});
    }

    addDeliveryAddress(){
        this.parent.setState({addEditDeliveryAddress : 1,deleteDeliveryAddress:false,formData:{...this.parent.state.formData,
            deliveryCompany:"",
            deliveryTel:"",
            deliveryStreet:"",
            deliveryStreetNumber:"",
            deliveryCity:"",
            deliveryZip:"",
        }});
    }

    addEditDeliveryAddress(addEditDeliveryAddress){

        var {deliveryAddressID,customerID,deliveryCompany,deliveryTel,deliveryStreet,deliveryStreetNumber,deliveryCity,deliveryZip} = this.parent.state.formData;
        
        if(deliveryCompany != ""){
            if(deliveryStreet != ""){
                if(deliveryStreetNumber != ""){
                    if(deliveryCity != ""){
                        if(deliveryZip != ""){

                            if(this.parent.state.addEditDeliveryAddress == 1 || this.parent.state.addEditDeliveryAddress == 0) deliveryAddressID = 0;

                            addEditDeliveryAddress({
                                variables:{
                                    deliveryAddressID,
                                    customerID,
                                    name:"",
                                    surname:"",
                                    company:deliveryCompany,
                                    tel:deliveryTel,
                                    street:deliveryStreet,
                                    streetNumber:deliveryStreetNumber,
                                    city:deliveryCity,
                                    zip:deliveryZip,
                                    countryID:60,
                                }
                            });

                        }else{
                            this.parent.setState({ deliveryError:"Nevyplnili jste PSČ."},() => {
                                setTimeout(() => this.parent.setState({ deliveryError:""}),4000);
                            });
                        }
                    }else{
                        this.parent.setState({ deliveryError:"Nevyplnili jste město."},() => {
                            setTimeout(() => this.parent.setState({ deliveryError:""}),4000);
                        });
                    }
                }else{
                    this.parent.setState({ deliveryError:"Nevyplnili jste číslo popisné."},() => {
                        setTimeout(() => this.parent.setState({ deliveryError:""}),4000);
                    });
                }
            }else{
                this.parent.setState({ deliveryError:"Nevyplnili jste ulici."},() => {
                    setTimeout(() => this.parent.setState({ deliveryError:""}),4000);
                });
            }
        }else{
            this.parent.setState({ deliveryError:"Nevyplnili jste název stavby / firmy."},() => {
                setTimeout(() => this.parent.setState({ deliveryError:""}),4000);
            });
        }
    }

    updateDeliveryAddresses(cache,response,customerID,deliveryAddressID){

        if(this.parent.state.addEditDeliveryAddress == 1 || this.parent.state.addEditDeliveryAddress == 0) deliveryAddressID = 0;

        const { allDeliveryAddresses } = cache.readQuery({ query: DELIVERY_ADDRESSES, variables:{customerID} });

        if(!(deliveryAddressID && deliveryAddressID != 0)){

            cache.writeQuery({ 
                query:DELIVERY_ADDRESSES,
                variables:{customerID},
                data:{
                    allDeliveryAddresses: [response.data.addEditDeliveryAddress,...allDeliveryAddresses]
                } 
            });

        }else{

            var arr = [...allDeliveryAddresses];
            
            arr.forEach((item,index) => {
                if(item.deliveryAddressID == response.data.addEditDeliveryAddress.deliveryAddressID){
                    arr[index] = response.data.addEditDeliveryAddress;
                }

            });

            cache.writeQuery({ 
                query:DELIVERY_ADDRESSES,
                variables:{customerID},
                data:{
                    allDeliveryAddresses: arr
                } 
            });
        }
    }

    updateDeliveryAddressesAfterDelete(cache,response,customerID,deliveryAddressID){

        const { allDeliveryAddresses } = cache.readQuery({ query: DELIVERY_ADDRESSES,variables:{customerID}});
        var arr = [...allDeliveryAddresses];

        arr.forEach((item,index) => {
            if(item.deliveryAddressID == deliveryAddressID){
                arr = [...arr.slice(0,index),...arr.slice(index + 1)]
            }
        });
        
        cache.writeQuery({ 
            query:DELIVERY_ADDRESSES,
            variables:{customerID},
            data:{
                allDeliveryAddresses: [...arr]
            } 
        });

    }

    editDeliveryAddress(deliveryAddressID,allDeliveryAddresses){

        if(allDeliveryAddresses && allDeliveryAddresses.length > 0){

            for(let val of allDeliveryAddresses){
                if(val.deliveryAddressID == deliveryAddressID){
                    this.parent.setState({addEditDeliveryAddress : 2,deleteDeliveryAddress:false,formData:{...this.parent.state.formData,
                        deliveryCompany:val.company,
                        deliveryTel:val.tel,
                        deliveryStreet:val.street,
                        deliveryStreetNumber:val.streetNumber,
                        deliveryCity:val.city,
                        deliveryZip:val.zip,
                    }});
                    break;
                }
            }

        }

    }

    setFiles(e){

        var files = e.target.files;
        var totalSize = 0;
        var checkType = "";
         
        for(let i in [...files]){
            totalSize += files[i].size;
            if(this.checkMimeType(files[i].type) == -1){
                if(checkType != "")checkType += ", ";
                checkType += files[i].name;
            }
        }

        if(checkType == ""){

            if(files.length <= MAX_ATTACHMENT_FILE_COUNT){
                if(totalSize <= MAX_ATTACHMENT_SIZE_MB * 1000000){
                    this.parent.setState({formData:{...this.parent.state.formData,files: files}});
                }else{
                    this.parent.setState({attachError:"Celkový počet MB překročil limit 10MB. Zvolte prosím menší soubory."},
                    () => setTimeout(() => this.parent.setState({attachError:""}),4000));
                }
                
            }else{
                this.parent.setState({attachError:"Překročili jste povolený počet příloh. Povolený počet je 5."},
                () => setTimeout(() => this.parent.setState({attachError:""}),4000));
            }

        }else{
            this.parent.setState({attachError:"Povolené soubory jsou pouze pdf, jpg, png, doc, docx, xls, xlsx, ppt, pptx. Nahráli jste soubor " + checkType},
            () => setTimeout(() => this.parent.setState({attachError:""}),4000));
        }
  
    }

    checkMimeType(type){

        var mimeTypes = [
            'image/jpeg',
            'application/pdf',
            'image/png',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'application/msword',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ]

        return mimeTypes.indexOf(type);

    }

    addGoods(){

        var newItem = {daikinCode:"",count:"",orderNumber:""}
        this.parent.setState({formData:{...this.parent.state.formData,goods:[...this.parent.state.formData.goods,newItem]}});
    }

    removeGoods(index){
        this.parent.setState({formData:{...this.parent.state.formData,goods:[...this.parent.state.formData.goods.slice(0,index),...this.parent.state.formData.goods.slice(index+1)]}});
    }

    setGoods(e,index){
        var name = e.target.name;
        var value = e.target.value;

        var arr = [...this.parent.state.formData.goods];
        arr[index] = {...arr[index],[name]:value};

        this.parent.setState({formData:{...this.parent.state.formData,goods:arr}});
    }

    setDeliveryDate(date){
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,deliveryDate: date}});
    }

    deleteDeliveryAddress(){
        this.parent.setState({deleteDeliveryAddress:true,addEditDeliveryAddress:0});
    }

    closeDelete(){
        this.parent.setState({deleteDeliveryAddress:false,addEditDeliveryAddress:0});
    }

    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default Order;