import gql from 'graphql-tag';


/**
 *  Seznam veškerých objednávek
 */
export const GET_ORDERS = gql`
    query AllOrdersForClient($filterParams:OrderFilterParams){
        allOrdersForClient(filterParams:$filterParams){           
            orderID
            customerID
            dateAdd
            company
            name
            surname
            offerNumber
            orderGoodsCount
            deliveryStreet
            deliveryStreetNumber
            deliveryCity
            deliveryZip
            customer{
                customerID
                sapCode
            }
        }
        orderForClientCount(filterParams:$filterParams)
        
    }
`;

/**
 *  Data objednávky
 */

export const GET_ORDER = gql`
    query Order($orderID: ID!){
        order(orderID:$orderID){
            orderID
            customerID
            orderNumber
            name
            surname
            company
            street
            streetNumber
            city
            zip
            dic
            ic
            tel
            intOrderNumber
            offerNumber
            deliveryDate
            deliveryCompany
            deliveryTel
            deliveryStreet
            deliveryStreetNumber
            deliveryCity
            deliveryZip
            note
            dateAdd
            customer{
              customerID
              sapCode
            }
            orderFiles{
                orderFileID
                orderID
                file
            }
            orderGoods{
                orderGoodID
                orderID
                daikinCode
                count
                orderNumber
            }
        }
        
    }
`;

export const GET_CUTOMER_DATA = gql`
  query getCustomerData {
    customerData{
        customerID
        name
        surname
        company
        email
        tel
        street
        streetNumber
        city
        zip
        countryID
        dic
        ic   
    }
  }
`;

export const DELIVERY_ADDRESSES = gql`
  query allDeliveryAddresses($customerID:ID) {
    allDeliveryAddresses(customerID:$customerID){
        deliveryAddressID
        name
        surname
        company
        tel
        street
        streetNumber
        city
        zip
        countryID  
    }
  }
`;

export const ADD_EDIT_DELIVERY_ADDRESS = gql`
    mutation AddEditDeliveryAddress(
      $deliveryAddressID:ID,
      $customerID:ID,
      $name: String,
      $surname: String,
      $company: String,
      $tel: String,
      $street: String,
      $streetNumber: String,
      $city: String,
      $zip: String,
      $countryID:ID,
    
    ){
      addEditDeliveryAddress(
          deliveryAddressID:   $deliveryAddressID, 
          customerID:          $customerID,
          name:                $name,
          surname:             $surname,
          company:             $company,
          tel:                 $tel,
          street:              $street,
          streetNumber:        $streetNumber,
          city:                $city,
          zip:                 $zip,
          countryID:           $countryID,
          
      ){
          deliveryAddressID
          name
          surname
          company
          tel
          street
          streetNumber
          city
          zip
          countryID      
      }
    }
`;

export const ADD_ORDER = gql`
    mutation AddOrder(
        $customerID:ID,
        $email:String,
        $deliveryAddressID:ID,
        $name:String,
        $surname:String,
        $company:String,
        $street:String,
        $streetNumber:String,
        $city:String,
        $zip:String,
        $dic:String,
        $ic:String,
        $tel:String,
        $intOrderNumber:String,
        $offerNumber:String,
        $deliveryDate:String,
        $deliveryCompany:String,
        $deliveryTel:String,
        $deliveryStreet:String,
        $deliveryStreetNumber:String,
        $deliveryCity:String,
        $deliveryZip:String,
        $note:String,
        $goods:[OrderGoods],
        $files:Upload
    
    ){
      addOrder(
          customerID: $customerID,
          email: $email,
          deliveryAddressID: $deliveryAddressID,
          name: $name,
          surname: $surname,
          company: $company,
          street: $street,
          streetNumber: $streetNumber,
          city: $city,
          zip: $zip,
          dic: $dic,
          ic: $ic,
          tel: $tel,
          intOrderNumber: $intOrderNumber,
          offerNumber: $offerNumber,
          deliveryDate: $deliveryDate,
          deliveryCompany: $deliveryCompany,
          deliveryTel: $deliveryTel,
          deliveryStreet: $deliveryStreet,
          deliveryStreetNumber: $deliveryStreetNumber,
          deliveryCity: $deliveryCity,
          deliveryZip: $deliveryZip,
          note: $note,
          goods: $goods,
          files:$files
          
      )
    }
`;

export const DELETE_DELIVERY_ADDRESS = gql`
    mutation DeleteDeliveryAddresses($deliveryAddressesID:ID!) {
        deleteDeliveryAddresses(deliveryAddressesID:$deliveryAddressesID)
    }
`;