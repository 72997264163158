import React,{ Component } from 'react';
import { Mutation, ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const LOGIN_USER = gql`
  mutation login($email: String!,$password: String!) {
    login(email: $email,password: $password){
        loginToken
        adminUser{
            name
            surname
        }
    }
  }
`;

export const GET_ADMIN_DATA_CONTEXT = gql`
  query AdminDataContext {
    adminDataContext{
        adminUserID
        name
        surname  
    }
  }
`;

export default class Login extends Component{

    constructor(props){
        super(props);
    }
  
    render(){
        return (
            <ApolloConsumer>
                {client => (
                    <Mutation
                    mutation={LOGIN_USER}
                    onCompleted={(data) => {

                        if(!data.login)this.props.error("Zadali jste špatné uživatelské jméno a heslo.");
                        else{
                            localStorage.setItem('token', data.login.loginToken);
                            localStorage.setItem('loggedUser', data.login.adminUser.name+" "+data.login.adminUser.surname);
                            localStorage.setItem('loginPlace','3fdc94bf1dd7dccf3d202a768df0a1eb');
                            client.writeData({ data: { isLoggedIn: true, loginPlace: "3fdc94bf1dd7dccf3d202a768df0a1eb"} });

                            if(data.login.adminUser.name)this.props.history.push('/admin/customer');

                        }
                    }}
                    >
                    {(login, { loading, error }) => {
                        // this loading state will probably never show, but it's helpful to
                        // have for testing
                        if (loading) return "";
                        if (error) return this.props.error(error);

                        return <button type="button" onClick={() => this.props.login(login)} className="btn btn-primary btn-blue btn-block">Přihlásit se</button>;
                    }}
                    </Mutation>
                )}
            </ApolloConsumer>
        );
    }
}