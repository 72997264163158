/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import AddEditCustomerAccount from './AddEditCustomerAccount';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Notification from '../../../../Library/notification';
import {Query,Mutation,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import NoItems from '../../../../GlobalComponents/NoItems';
import {GET_CUSTOMER_ACCOUNTS,DELETE_CUSTOMER_ACCOUNT} from '../Queries/customerAccount.js';
import edit from '../../../../Public/Images/edit.svg';
import CustomerAccountLib from '../Library/customerAccount';
import Helper from '../../../../Library/helper';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteCustomerAccounts:"",
    selectedCustomerAccounts:[],
    selectedCustomerAccountID:0,
}

class CustomerAccount extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal     = this.openCloseModal.bind(this);
       this.deleteCustomerAccounts = this.deleteCustomerAccounts.bind(this);
       this.delCustomerAccount     = this.delCustomerAccount.bind(this);
       this.showDelNotifi      = this.showDelNotifi.bind(this);

    }

    openCloseModal(type,customerAccountID){
        this.setState({
            showAdd:type,
            selectedCustomerAccountID:customerAccountID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteCustomerAccounts funkce, která posílá data na server
     * @param {*} customerAccountID ID uživateslkého účtu
     */
    
    deleteCustomerAccounts(e,deleteCustomerAccounts,customerAccountID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedCustomerAccounts];

        if(!checked){
            for(let i in this.state.selectedCustomerAccounts){
                if(this.state.selectedCustomerAccounts[i] == customerAccountID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [customerAccountID,...arr];
        }

        
        this.setState({
            deleteCustomerAccounts,
            selectedCustomerAccounts:arr
        });
  
    }

    delCustomerAccount(action){

        if(action){

            let customerAccountID = this.state.selectedCustomerAccounts.join(",");

            this.state.deleteCustomerAccounts({
                variables:{
                    customerAccountsID: customerAccountID
                }
            })
        }
        this.setState({
            showAdd : false,
            showDeleteNotifi:false,
            deleteCustomerAccounts:"",
            selectedCustomerAccounts:[],
            selectedCustomerAccountID:0
        });
    }

    showDelNotifi(){

        if(this.state.selectedCustomerAccounts.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste uživatelský účet.',false,true,this.props.client);
        }
    }

    render(){

        const {selectedCustomerAccounts,selectedCustomerAccountID} = this.state;
        const {customerID} = this.props;

        return(

            <div>
                        
                <Query 
                    query={GET_CUSTOMER_ACCOUNTS}
                    variables = {{customerID}}
                    fetchPolicy = {'network-only'}
                >
                    {({data,loading,error,fetchMore }) => {
                        
                        if(loading) return (<Loading />);
                        if(error){
                            const helper = new Helper(); 
                            return (<Error text={helper.getApolloErrorText(error)} />);
                        }

                        return(
                            
                            <div className="card main">
                                <div className="card-header d-flex">
                                                                               
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => {this.openCloseModal(true,0);}}>Přidat</button>
                                    <button className="btn btn-danger btn-thiner ml-2" style={{"minWidth":"160px"}} onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                </div>
                                <div className="card-body">
                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            
                                            <div className="">Email</div>
                                            <div className="text-center">Telefon</div>
                                            <div className="text-right">Možnosti</div>
                                        </div>
                                    </div>
                                    <Mutation
                                        mutation = {DELETE_CUSTOMER_ACCOUNT}
                                        onError = {(error) => { 
                                            const helper = new Helper(); 
                                            let notify = new Notification();
                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                        }}

                                        update = {async (cache, response) => {

                                            let customerAccountLib = new CustomerAccountLib(this);
                                            customerAccountLib.updateAfterDeleteCustomerAccount(cache, response,customerID);

                                            let notify = new Notification();
                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                        
                                        }}
                                    >
                                        {
                                            (deleteCustomerAccounts,{error}) => {
                                                                                                        
                                                return(
                                                    <Fragment>
                                                        {data.allCustomerAccounts && data.allCustomerAccounts.map((item,index) => {

                                                            var checked = false;
                                                            for(let i in selectedCustomerAccounts){
                                                                if(selectedCustomerAccounts[i] == item.customerAccountID)checked = true;
                                                            }
                                                                                                                            
                                                            return (
                                                            
                                                                <div key={index} className="data-list-item-content">
                                                                    
                                                                    <div>{item.email}</div>
                                                                    <div className="text-center">{item.tel}</div>
                                                                    <div className="text-right">
                                                                        <img onClick={() => this.openCloseModal(true,item.customerAccountID)} className="edit-icon" src={edit} /> 
                                                                        <input className="delete-checked" type="checkbox" name="deleteCustomerAccounts[]" checked={checked} onChange = {(e) => this.deleteCustomerAccounts(e,deleteCustomerAccounts,item.customerAccountID)} />
                                                                    </div>
                                                                </div>
                                                                        
                                                            )
                                                            
                                                        })} 

                                                    </Fragment>
                                                )
                                            }
                                        }
                                    </Mutation>
                                </div>
                                {data.allCustomerAccounts && data.allCustomerAccounts.length == 0 &&
                                    <NoItems text={"Momentálně se zde nenachází žádné uživatelské účty."} />
                                }
                                </div>
                                
                            </div>
                                
                            );
                        }
                    }
                </Query>

                   	
                {this.state.showAdd &&
                    <AddEditCustomerAccount customerID = {customerID} openCloseModal={this.openCloseModal} selectedCustomerAccountID={selectedCustomerAccountID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané uživatelské účty ?'} callback={this.delCustomerAccount} /> }	
            
            </div>
        );

    }

}

export default withApollo(CustomerAccount);