/*
    routování aplikace
*/
import React, { Component } from 'react';
import {Switch, Route } from 'react-router-dom';
import AdminLoginForm from '../Modules/Admin/Auth/Components/LoginForm';
import LoginForm from '../Modules/Client/Auth/Components/LoginForm';
import AdminLayout from '../Layout/Admin/Layout.js';
import Layout from '../Layout/Client/Layout.js';
import ResetPassword from '../Modules/Client/Auth/Components/ResetPassword';
import NewPassword from '../Modules/Client/Auth/Components/NewPassword';
import NoMatch from './NoMatch';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../Public/Css/main.css';
import '../Public/Css/xlg.css';
import '../Public/Css/lg.css';
import '../Public/Css/md.css';
import '../Public/Css/sm.css';
import '../Public/Css/xs.css';


class Routes extends Component {
	
  render() {
	  	  
    return (
        <Switch>
        
          <Route exact path='/admin' component={AdminLoginForm}></Route>
          <Route path='/admin/:l' component={AdminLayout}></Route>

          <Route exact path='/' component={LoginForm}></Route>
          <Route exact path='/zapomenute-heslo' component={ResetPassword}></Route>
          <Route exact path='/nove-heslo/:code' component={NewPassword}></Route>

          <Route path='/:l' component={Layout}></Route>
          
	        <Route component={NoMatch} />

	      </Switch>
    );
  }
  
}
export default Routes;