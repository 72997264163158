/*
    Formulář pro přihlášení se do adminu
*/
import React,{ Component,Fragment } from 'react';
import { Link } from "react-router-dom";
import LoginHeader from '../../../../Layout/Client/LoginHeader';
import Footer from '../../../../Layout/Client/Footer';
import {Mutation,withApollo} from 'react-apollo';
import {LOGIN_CUSTOMER} from '../Queries/login';
import { NavLink } from "react-router-dom";
import { GET_CUTOMER_DATA } from '../../Order/Queries/order';


const INITIAL_STATE = {	 
    email: '',
	  password: '',
	  error: null
};

class LoginForm extends Component {
		
  constructor(props){
	   	
	  super(props);
	  
      this.checkLogin = this.checkLogin.bind(this);
	    this.setError  = this.setError.bind(this);
	    this.state = { ...INITIAL_STATE };
	  
  }	

  componentDidMount(){

      this.props.client.resetStore();

		  localStorage.removeItem("token");
      localStorage.removeItem("loggedUser");
      localStorage.removeItem("loginPlace");
      
      this.props.client.writeQuery({ 
          query:GET_CUTOMER_DATA,
          data:{
            customerData: null
          } 
      });
      
  }
  
  checkLogin(login){
	    
	  const {email,password} = this.state;
	  	  
	  if(password !== '' && email !== ''){

		  login({variables:{email,password}});  
		
	  }else{
		  this.setState({'error':'Nevyplnili jste email nebo heslo.'});
		  setTimeout(() => this.setState({'error':''}),4000);
	  }

  }

  setError(error){
      this.setState({'error':error});
      setTimeout(() => this.setState({'error':''}),4000);
  }

  render() {

  const {email,password,error} = this.state;  
  const {client} = this.props; 
	  
    return (
      <div id="client-container">			       
        
        <LoginHeader />  
        <div id="client-content" className="content-center">

          <div id="client-login">

            <h2>Přihlásit se</h2>

            <div className="form-group">
                <label htmlFor="username">Uživatelské jméno</label>
                <div>
                    <input className="form-control" id="username" type="text" name="email" placeholder="vas@email.cz" 
                      onChange={event => this.setState({'email':event.target.value})}
		        			    value={email} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">Heslo</label>
                <div>
                    <input 
                       onChange={event => this.setState({'password':event.target.value})}
                       value={password}
                       className="form-control" id="password" type="password" name="password" placeholder="******" />
                </div>
            </div>
            <div className="form-group">
                <Mutation
                    mutation={LOGIN_CUSTOMER}
                    onCompleted={(data) => {

                        if(!data.loginCustomer){
                          this.setState({error: "Zadali jste špatné uživatelské jméno a heslo."});
                          setTimeout(() => this.setState({error:""}),4000);
                        }else{
                            localStorage.setItem('token', data.loginCustomer.loginToken);
                            localStorage.setItem('loggedUser', data.loginCustomer.customer.name+" "+data.loginCustomer.customer.surname);
                            localStorage.setItem('loginPlace','1d1af138be991e18b3e98c667a2567c9');
                            client.writeData({ data:{isLoggedIn: true, loginPlace: '1d1af138be991e18b3e98c667a2567c9'}});

                            this.props.history.push('/objednavka');

                        }
                    }}
                    >
                    {(loginCustomer, { loading, err }) => {
                        // this loading state will probably never show, but it's helpful to
                        // have for testing
                        if (loading) return "";
                        if (err) error = err;
                        
                        return(<button onClick={() => this.checkLogin(loginCustomer)} className="btn btn-primary">Přihlásit se</button>)
                    }}
                </Mutation>
            </div>
            {error &&
              <div className="alert alert-danger">{error}</div>
            }
            <div className="form-group forgot-password">
              <p><NavLink to="zapomenute-heslo">Zapomenuté heslo</NavLink></p>
              <p><a target="_blank" href="http://www.daikin.cz">Web Daikin.cz</a></p>
            </div>

          </div>

        </div>
        <Footer />

      </div>
    );
  }
}

export default withApollo(LoginForm);
