import gql from 'graphql-tag';

/**
 *  Seznam veškerých objednávek
 */
export const GET_ORDERS = gql`
    query AllOrdersForAdmin($filterParams:OrderFilterParams){
        allOrdersForAdmin(filterParams:$filterParams){           
            orderID
            customerID
            dateAdd
            company
            name
            surname
            offerNumber
            orderGoodsCount
            deliveryStreet
            deliveryStreetNumber
            deliveryCity
            deliveryZip
            deliveryDate
            processed
            customer{
                customerID
                sapCode
            }
        }
        orderForAdminCount(filterParams:$filterParams)
        
    }
`;

/**
 *  Data objednávky
 */

export const GET_ORDER = gql`
    query Order($orderID: ID!){
        order(orderID:$orderID){
            orderID
            customerID
            orderNumber
            name
            surname
            company
            street
            streetNumber
            city
            zip
            dic
            ic
            tel
            intOrderNumber
            offerNumber
            deliveryDate
            deliveryCompany
            deliveryTel
            deliveryStreet
            deliveryStreetNumber
            deliveryCity
            deliveryZip
            note
            dateAdd
            processed
            customer{
              customerID
              sapCode
            }
            orderFiles{
                orderFileID
                orderID
                file
            }
            orderGoods{
                orderGoodID
                orderID
                daikinCode
                count
                orderNumber
            }
        }
        
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ORDERS = gql`
    mutation deleteOrders($orderIDs:ID!) {
        deleteOrders(orderIDs:$orderIDs)
    }
`;

/**
 * Úprava statusu.
 */
export const CHANGE_ORDER_STATUS = gql`
    mutation changeOrderStatus($orderID:ID,$status:Int) {
        changeOrderStatus(orderID:$orderID,status:$status)
    }
`;