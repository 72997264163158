/*
    Přidání potahu
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CUSTOMER_ACCOUNT} from '../Queries/customerAccount';
import CustomerAccountLib from '../Library/customerAccount';
import Notification from '../../../../Library/notification';
import Helper from '../../../../Library/helper';

const INITIAL_STATE = {
    apolloError:"",
    selectedCustomerAccountID:null,
    allCountries:[],
    formData:{
        customerAccountID:0,
        email:"",
        tel:""        
    }
}

class AddEditCustomerAccount extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.customerAccount = new CustomerAccountLib(this); 
        this.customerAccount.getData();
        
    }
    
    render(){

        const {selectedCustomerAccountID,apolloError} = this.state;
        const {customerID} = this.props;

        console.log(customerID);

        return(

            <Mutation 
                mutation={ADD_EDIT_CUSTOMER_ACCOUNT}
                update = {async (cache, response) => {

                    this.customerAccount.updateCustomerAccountList(cache,response,selectedCustomerAccountID,customerID);

                    let notify = new Notification();

                    if(selectedCustomerAccountID && selectedCustomerAccountID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {
                    this.props.openCloseModal(false);   
                }}
                
            >
            {
                (addCustomerAccount,{data,loading,error}) => {

                    const {
                        email,tel
                    } = this.state.formData;

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedCustomerAccountID && selectedCustomerAccountID != 0 ? "Úprava uživatelského účtu" : "Přidání uživatelského účtu")}</h4>
                                    <button type="button" className="close" onClick={() => this.props.openCloseModal(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    
                                    <div>
                                        {!loading ?
                                            <Fragment>
                                                {error || apolloError ?
                                                    <Error text={error || apolloError} />
                                                :
                                                    <div className="row">

                                                                                                                
                                                        <div className="col-12 col-sm-6">
                                                            <label className="input-label">Email</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="email" value={email} onChange={(e) => this.customerAccount.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6">
                                                            <label className="input-label">Telefon</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="tel" value={tel} onChange={(e) => this.customerAccount.formHandle(e)} />
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <p>Po přidání nového uživatelského účtu bude na daný účet zaslán email, přes který si daný uživatel zvolí své heslo.</p>
                                                        </div>
                                                    </div>
                                                }
                                            </Fragment>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.customerAccount.addCustomerAccount(addCustomerAccount,customerID)}>{(selectedCustomerAccountID && selectedCustomerAccountID != 0 ? "Upavit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCustomerAccount);