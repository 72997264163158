let graphqlServerUrl              = "https://server.daikin.cz/graphql";
let serverUrl                     = "https://server.daikin.cz";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8897/graphql";
    serverUrl                     = "http://localhost:8897";
}

//export const GRAPHQL_SERVER_URL              = "http://localhost:8897/graphql";
//export const SERVER_URL                      = "http://localhost:8897";
export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
export const TINYMCE_IMAGE_URL               = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                = "Public/Files/TinyMCE";
export const MAX_ATTACHMENT_SIZE_MB          = 10; 
export const MAX_ATTACHMENT_FILE_COUNT       = 5;            
