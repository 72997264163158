/*
    Formulář pro přihlášení se do adminu
*/
import React,{ Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../../../../Public/Images/logo.png';
import emailIcon from '../../../../Public/Images/email.svg';
import key from '../../../../Public/Images/key.svg';
import Login from '../Queries/Login';

const INITIAL_STATE = {	 
    email: '',
	password: '',
	error: null
};

class LoginForm extends Component {
		
  constructor(props){
	   	
	  super(props);
	  
      this.checkLogin = this.checkLogin.bind(this);
	  this.setError  = this.setError.bind(this);
	  this.state = { ...INITIAL_STATE };
	  
  }	
  
  checkLogin(login){
	    
	  const {email,password} = this.state;
	  	  
	  if(password !== '' && email !== ''){

		login({variables:{email,password}});  
		
	  }else{
		  this.setState({'error':'Nevyplnili jste email nebo heslo.'});
		  setTimeout(() => this.setState({'error':''}),3000);
	  }

  }

  setError(error){
      this.setState({'error':error});
      setTimeout(() => this.setState({'error':''}),3000);
  }

  render() {

	const {email,password,error,} = this.state;  
	  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <div><img alt="rea shop" src={logo} /></div>
            </div>
        	<div className="panel">
	        	<div className="inner-panel">
	        	    <form onSubmit={this.onSubmit}>
		        		<div className="form-group">
		        			
		        			<div className="input-group">
		        			  <div className="input-group-prepend">
		        			    <span className="input-group-text" ><img alt="" src={emailIcon} /></span>
		        			  </div>
		        			  <input
		        			      onChange={event => this.setState({'email':event.target.value})}
		        			      value={email}
		        			      type="text" className="form-control" placeholder="Uživatelské jméno" aria-label="Username" />
		        			</div>
	
		        		</div>
		        		<div className="form-group">
		        				        			
		        			<div className="input-group">
		        			  <div className="input-group-prepend">
		        			     <span className="input-group-text" ><img alt="" src={key} /></span>
		        			  </div>
		        			  <input
		        			      onChange={event => this.setState({'password':event.target.value})}
		        			      value={password}
		        			      type="password" placeholder="Heslo" className="form-control" aria-label="Password" />
		        			</div>
		        			
		        		</div>
		        		
	        			<div className="form-group">
		    			
                            <Login login={this.checkLogin} error={this.setError} history={this.props.history} />

		    			</div>
		    			{false &&
		    			<div className="text-center">
	        				<Link to='/reset-password'>Zapomněli jste heslo?</Link>
	        			</div>
						}
		    			{error && <div className="alert alert-danger mt-4 mb-0">{error}</div>}
	        		</form>
	        		
	        	</div>
        	</div>
        </div>
    );
  }
}

export default LoginForm;
