import React,{ Component,Fragment } from 'react';
import Header from '../../../../Layout/Client/Header';
import Footer from '../../../../Layout/Client/Footer';
import {Mutation,withApollo} from 'react-apollo';
import { NavLink } from "react-router-dom";
import AuthLib from '../Library/auth';

const INITIAL_STATE = {	 
    email: '',
	  error: null,
  	message:''
};

class ResetPassword extends Component {
		
  constructor(props){
	    
	  super(props);
	  this.goBack   = this.goBack.bind(this);
	  this.state    = { ...INITIAL_STATE };
	  
  }	
  
  componentDidMount(){
     this.authLib = new AuthLib(this);
  }
    
  goBack(){ 
	  this.props.history.push("/");
  }
  
  render() {

	const {email,error,message} = this.state; 
	const {client} = this.props; 
	  
    return (
        <div id="client-container">			       
        
        <Header />  
        <div id="client-content" className="content-center">

          <div id="client-login">

            <h2>Zapomenuté heslo</h2>

		      	<div className="form-group">
                <p>Zadejte níže prosím svůj email pro vygenerování nového hesla.</p>
            </div>

            <div className="form-group">
                <label htmlFor="username">Email</label>
                <div>
                    <input className="form-control" id="username" type="text" name="email" placeholder="vas@email.cz" 
                      onChange={event => this.setState({'email':event.target.value})}
		        			    value={email} />
                </div>
            </div>
            
            <div className="form-group">
                
                <button onClick={() => this.authLib.resetPassword()} className="btn btn-primary">Vygenerovat</button>
                    
            </div>

            {error &&
              <div className="alert alert-danger">{error}</div>
            }
            {message &&
              <div className="alert alert-success">{message}</div>
            }

            <div className="form-group forgot-password">
              <p><NavLink to="">Zpět na přihlášení</NavLink></p>
              <p><a target="_blank" href="http://www.daikin.cz">Web Daikin.cz</a></p>
            </div>

          </div>

        </div>
        <Footer />

      </div>
    );
  }
}



export default withApollo(ResetPassword);
