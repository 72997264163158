import {SEND_FORGOT_PASS_EMAIL} from '../Queries/changePassword';
import Helper from '../../../../Library/helper';
/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci autentikací
 */

class Auth {

    constructor(parent){
        this.parent = parent;
    }

    savePassword(saveNewPassword,passCode){

        const {password,checkPassword} = this.parent.state;
        
        if(passCode && passCode != ""){
            if(password !== '' && checkPassword !== ''){
                if(password === checkPassword){
                    
                    saveNewPassword({
                        variables:{
                            passCode:passCode,
                            password:password
                        }
                    });

                }else{
                    this.parent.setState({error:'Nová hesla nejsou stejná.'});
                    setTimeout(() => this.parent.setState({'error':''}),3000);
                }
            }else{
                this.parent.setState({error:'Nevyplnili jste heslo.'});
                setTimeout(() => this.parent.setState({'error':''}),3000);
            }
        }else{
            this.parent.setState({error:'Špatný uživatel.'});
            setTimeout(() => this.parent.setState({'error':''}),3000);
        }

    }

    resetPassword(){
	  	  
        const {email} = this.parent.state;
        const {history,client} = this.parent.props;
              
        if(email !== ''){
  
          var helper = new Helper();
           
          if(helper.validateEmail(email)){
  
              client.mutate({
                  mutation: SEND_FORGOT_PASS_EMAIL,
                  variables:{email},
                  update:(cache, {data}) => {
                      if(data.sendForgotPassEmail == ""){
                          this.parent.setState({'message':'Na Váš email jsme zaslali odkaz pro vygenerování nového hesla. Tento odkaz je platný pouze 15 minut.'});
                          setTimeout(() => history.push("/"),4000);
                      }else{
                          this.parent.setState({'error':data.sendForgotPassEmail});
                          setTimeout(() => this.parent.setState({'error':''}),4000);
                      }
                  }
              })
            
          }else{
              this.parent.setState({'error':'Email není ve správném tvaru'});
              setTimeout(() => this.parent.setState({'error':''}),4000);
          }
            
        }else{
            this.parent.setState({'error':'Nevyplnili jste email.'});
            setTimeout(() => this.parent.setState({'error':''}),4000);
        }
        
    }

    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default Auth