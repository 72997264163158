/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component } from 'react';


class Footer extends Component {
	
	  constructor(props){
		  super(props);
		  		  
	  }
	  
	  render() {
						  
	    return (
	    	<footer>
                <p>© 2020 Daikin Airconditioning Central</p>
                <p>Europe Czech Republic spol. s r.o.</p>
            </footer>
	    );
	  }
  
}


export default Footer;
