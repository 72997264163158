import React,{ Component,Fragment } from 'react';
import {withApollo,Query,Mutation} from 'react-apollo';
import OrderLib from '../Library/order';
import plusIcon from '../../../../Public/Images/add.svg';
import deleteIcon from '../../../../Public/Images/delete.svg';
import calendarIcon from '../../../../Public/Images/calendar.svg';
import editIcon from '../../../../Public/Images/circle_edit.svg';
import DatePicker,{ registerLocale } from 'react-datepicker';
import {DELIVERY_ADDRESSES,ADD_EDIT_DELIVERY_ADDRESS,DELETE_DELIVERY_ADDRESS,ADD_ORDER} from '../Queries/order';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import Message from '../../../../GlobalComponents/Message';
import Helper from '../../../../Library/helper';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

registerLocale('cs', cs);

const INITIAL_STATE = {
    inputMethod:1,
    deliveryPlace:1,
    addEditDeliveryAddress:0,
    deleteDeliveryAddress:false,
    deliveryError:"",
    deliveryMessage:"",
    err:"",
    message:"",
    attachError:"",
    formData:{
        customerID:0,
        email:"",
        name:"",
        surname:"",
        company:"",
        street:"",
        streetNumber:"",
        city:"",
        zip:"",
        dic:"",
        ic:"",
        tel:"",
        intOrderNumber:"",
        offerNumber:"",
        deliveryDate: "",
        deliveryAddressID:0,
        deliveryCompany:"",
        deliveryTel:"",
        deliveryStreet:"",
        deliveryStreetNumber:"",
        deliveryCity:"",
        deliveryZip:"",
        note:"",
        checkTerm:false,
        files:[],
        goods:[{
            daikinCode:"",
            count:"",
            orderNumber:""
        }]
    }
}

class Order extends Component{

    constructor(pros){
        super(pros);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount(){
        this.orderLib = new OrderLib(this);
        this.orderLib.getData();
    }

    render(){

        const{inputMethod,formData,deliveryPlace,addEditDeliveryAddress,deleteDeliveryAddress,deliveryError,deliveryMessage,message,attachError} = this.state;
        var{err} = this.state;


        return(
            		       
            <div id ="order">

                <h2>Objednávka</h2>

                <section>
                    <div className="header">
                        <div className="sequence">1</div>
                        Údaje
                    </div>
                    <div className="content">

                        <div className="row">

                            <div className="col-12 col-lg-3 company">
                                <div className="form-group">
                                    <label>Název firmy <span>*</span></label>
                                    <div>
                                        <input type="text" name="company" className="form-control" placeholder="Firma s.r.o." value={formData.company} onChange={(e) => this.orderLib.formHandle(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9">

                                <div className="address">
                                    <div className="row">

                                        <div className="col-12 address-header">
                                            <strong>Sídlo</strong>
                                        </div>
                                    
                                        <div className="col-12 col-sm-8 col-md-4">
                                            <label>Ulice <span>*</span></label>
                                            <div>
                                                <input type="text" name="street" className="form-control" placeholder="Ulice" value={formData.street} onChange={(e) => this.orderLib.formHandle(e)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4 col-md-2">
                                            <label>Č. popisné <span>*</span></label>
                                            <div>
                                                <input type="text" name="streetNumber" className="form-control" placeholder="123/4" value={formData.streetNumber} onChange={(e) => this.orderLib.formHandle(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-4">
                                            <label>Město <span>*</span></label>
                                            <div>
                                                <input type="text" name="city" className="form-control" placeholder="Praha" value={formData.city} onChange={(e) => this.orderLib.formHandle(e)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4 col-md-2">
                                            <label>PSČ <span>*</span></label>
                                            <div>
                                                <input type="text" name="zip" className="form-control" placeholder="110 00" value={formData.zip} onChange={(e) => this.orderLib.formHandle(e)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="form-group">
                                    <label>DIČ <span>*</span></label>
                                    <div>
                                        <input type="text" name="dic" className="form-control" placeholder="CZ123456789" value={formData.dic} onChange={(e) => this.orderLib.formHandle(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="form-group">
                                    <label>Jméno objednávajícího <span>*</span></label>
                                    <div>
                                        <input type="text" name="name" className="form-control" placeholder="Jméno" value={formData.name} onChange={(e) => this.orderLib.formHandle(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="form-group">
                                    <label>Příjmení objednávajícího <span>*</span></label>
                                    <div>
                                        <input type="text" name="surname" className="form-control"  placeholder="Příjmení" value={formData.surname} onChange={(e) => this.orderLib.formHandle(e)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="form-group">
                                    <label>Telefon <span>*</span></label>
                                    <div>
                                        <input type="text" name="tel" className="form-control" placeholder="+420 123 456 789" value={formData.tel} onChange={(e) => this.orderLib.formHandle(e)} />
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                </section>

                <section>
                    <div className="header">
                        <div className="sequence">2</div>
                        Zboží
                    </div>
                    <div className="content">

                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <div className="form-group">
                                    <label>Způsob zadání <span>*</span></label>
                                    <div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio1" name="inputMethod" className="custom-control-input" value="1" checked={inputMethod == 1} onChange={(e) => this.setState({inputMethod:1})} />
                                            <label className="custom-control-label" htmlFor="customRadio1">Dle nabídky</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio2" name="inputMethod" className="custom-control-input" value="2" checked={inputMethod == 2} onChange={(e) => this.setState({inputMethod:2})} />
                                            <label className="custom-control-label" htmlFor="customRadio2">Vlastní specifikace zboží</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio3" name="inputMethod" className="custom-control-input" value="3" checked={inputMethod == 3} onChange={(e) => this.setState({inputMethod:3})} />
                                            <label className="custom-control-label" htmlFor="customRadio3">Vlastní specifikace v příloze</label>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9">

                                {inputMethod == 1 &&
                                
                                    <div className="row">

                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label>Číslo nabídky <span>*</span></label>
                                                <div>
                                                    <input type="text" name="offerNumber" className="form-control"  placeholder="NABCZ195222" value={formData.offerNumber} onChange={(e) => this.orderLib.formHandle(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label>Interní číslo objednávky </label>
                                                <div>
                                                    <input type="text" name="intOrderNumber" className="form-control"  placeholder="volitelné" value={formData.intOrderNumber} onChange={(e) => this.orderLib.formHandle(e)}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                }

                                {inputMethod == 2 &&
                                
                                    <div className="row goods">

                                        {formData.goods.map((item,index) => (
                                            <Fragment key={index}>
                                                <div className="col-8 col-sm-5 col-md-5">
                                                    <div>
                                                        <label>Kód zboží Daikin <span>*</span></label>
                                                        
                                                        <div key={index} className="form-group">
                                                            <input type="text" name="daikinCode" className="form-control"  placeholder="KOD" value={item.daikinCode} onChange={(e) => this.orderLib.setGoods(e,index)} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-3 col-md-2">
                                                    <div>
                                                        <label>Množství <span>*</span></label>
                                                        
                                                        <div className="form-group">
                                                            <input type="number" name="count" className="form-control"  placeholder="0" value={item.count} onChange={(e) => this.orderLib.setGoods(e,index)}/>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4 col-md-5">
                                                    <div>
                                                        <label>Interní č. objednávky </label>

                                                        <div className="form-group">
                                                            {index != 0 ?
                                                                    <div className="input-group">
                                                                        <input type="text" name="orderNumber" className="form-control"  placeholder="volitelne" value={item.orderNumber} onChange={(e) => this.orderLib.setGoods(e,index)}/>
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text" id="basic-addon2"><img className="cursor-pointer" src={deleteIcon} onClick={() => this.orderLib.removeGoods(index)} /></span>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    <input type="text" name="orderNumber" className="form-control"  placeholder="volitelne" value={item.orderNumber} onChange={(e) => this.orderLib.setGoods(e,index)}/>
                                                            }
                                                            
                                                        </div>

                                                        
                                                    </div>
                                                </div>
                                                {formData.goods.length > 1 && (index + 1 != formData.goods.length) && <div className="col-12"><hr /></div>}
                                            </Fragment>
                                        ))}
                                        <div className="col-12">
                                            <button className="btn-order" onClick={() => this.orderLib.addGoods()}><img src={plusIcon} /> Přidat další </button>       
                                        </div>

                                    </div>

                                }

                                {inputMethod == 3 &&
                                
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Příloha <span>*</span></label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="files" multiple onChange={(e) => this.orderLib.setFiles(e)} />
                                                        <label className="custom-file-label" htmlFor="files">

                                                            {formData.files && formData.files.length > 0 ?
                                                                <Fragment>
                                                                    {[...formData.files].map((item,index) => (
                                                                        <span className="file" key={index}>{(index != 0 ? ", " : "")}{item.name}</span>
                                                                    ))}
                                                                </Fragment>
                                                            :
                                                            ""
                                                            }

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {attachError != "" &&
                                            <div className="col-12">
                                                <Error text={attachError} />
                                            </div>
                                        }
                                       

                                    </div>

                                }

                                
                            </div>
                            
                        </div>

                    </div>

                </section>

                <section>
                    <div className="header">
                        <div className="sequence">3</div>
                        Termín
                    </div>
                    <div className="content">

                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label>Požadovaný termín dodání <span>*</span></label>
                                    <div className="relative">
                                        <img src={calendarIcon} className="calendar" />
                                        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" locale="cs" selected={formData.deliveryDate} onChange={date => this.orderLib.setDeliveryDate(date)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 d-flex align-items-center">

                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkTerm" name="checkTerm" value={formData.checkTerm}  onChange={(e) => this.orderLib.formHandle(e)} />
                                    <label className="custom-control-label blue-color" htmlFor="checkTerm">Zákazník potvrzuje zajištění převzetí zboží ve výše uvedený den /  změna termínu dodání je možná při avizaci 48 hodin před dodávkou</label>
                                </div>

                                
                            </div>
                            
                            
                        </div>

                    </div>

                </section>

                <section>
                    <div className="header">
                        <div className="sequence">4</div>
                        Místo
                    </div>
                    <div className="content">

                        <Query
                            query={DELIVERY_ADDRESSES}
                            variables={{customerID:formData.customerID}}
                        >
                            {({data,loading,error}) => {

                                if(error){
                                    var helper = new Helper();
                                    err = helper.getApolloErrorText(error);

                                    return (<Error text={err} />);
                                }
                                if(loading)return(<Loading />);

                                return(
                                    <div className="row">
                                        <div className="col-12 col-lg-3">
                                            <div className="form-group">
                                                <label>Místo dodání <span>*</span></label>
                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="deliveryPlace1" name="deliveryPlace" className="custom-control-input" value="1" checked={deliveryPlace == 1} onChange={(e) => this.setState({deliveryPlace:1})} />
                                                        <label className="custom-control-label" htmlFor="deliveryPlace1">Na fakturační adresu</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="deliveryPlace2" name="deliveryPlace" className="custom-control-input" value="2" checked={deliveryPlace == 2} onChange={(e) => this.setState({deliveryPlace:2})} />
                                                        <label className="custom-control-label" htmlFor="deliveryPlace2">Na jinou adresu</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-9">

                                            {deliveryPlace == 2 &&
                                            
                                                
                                                <div className="row">

                                                    <div className="col-12 col-md-8">
                                                        <div className="form-group">
                                                            <label>Vyberte adresu <span>*</span></label>
                                                            <select name="deliveryAddressID" value={formData.deliveryAddressID} className="form-control" onChange={(e) => this.orderLib.setDeliveryAddress(e)}>
                                                                <option value="0">---vyberte adresu ---</option>
                                                                {data.allDeliveryAddresses && data.allDeliveryAddresses.map((item,index) => (
                                                                    <option key={index} value={item.deliveryAddressID}>{item.company}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                                                            
                                                    <div className="col-12">
                                                        <button className="btn-order" onClick={() => this.orderLib.addDeliveryAddress()}><img src={plusIcon} /> Přidat novou </button>
                                                        {formData.deliveryAddressID != 0 && <button className="btn-order" onClick={() => this.orderLib.editDeliveryAddress(formData.deliveryAddressID,data.allDeliveryAddresses)}><img src={editIcon} /> Upravit </button> } 
                                                        {formData.deliveryAddressID != 0 && <button className="btn-order" onClick={() => this.orderLib.deleteDeliveryAddress()}><img src={deleteIcon} /> Smazat </button>  }       
                                                    </div>

                                                    {(addEditDeliveryAddress == 1 || addEditDeliveryAddress == 2) &&

                                                        <div className="col-12">
                                                            <div className="address mt-2">
                                                                <div className="row">

                                                                    <div className="col-12 address-header">
                                                                        <strong>{(addEditDeliveryAddress == 1 ? "Nová dodací adresa" : "Úprava dodací adresy")}</strong>
                                                                    </div>
                                                                
                                                                    <div className="col-12 col-sm-6 col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Název stavby / firmy <span>*</span></label>
                                                                            <div>
                                                                                <input type="text" name="deliveryCompany" className="form-control" placeholder="Stavba / firma" value={formData.deliveryCompany} onChange={(e) => this.orderLib.formHandle(e)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6  col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Telefon</label>
                                                                            <div>
                                                                                <input type="text" name="deliveryTel" className="form-control" placeholder="+420 123 456 789" value={formData.deliveryTel} onChange={(e) => this.orderLib.formHandle(e)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4"></div>
                                                                    <div className="col-12 col-sm-6  col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Ulice <span>*</span></label>
                                                                            <div>
                                                                                <input type="text" name="deliveryStreet" className="form-control" placeholder="Ulice" value={formData.deliveryStreet} onChange={(e) => this.orderLib.formHandle(e)}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6  col-md-2">
                                                                        <div className="form-group">
                                                                            <label>Č. popisné <span>*</span></label>
                                                                            <div>
                                                                                <input type="text" name="deliveryStreetNumber" className="form-control" placeholder="123/4" value={formData.deliveryStreetNumber} onChange={(e) => this.orderLib.formHandle(e)}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6  col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Město <span>*</span></label>
                                                                            <div>
                                                                                <input type="text" name="deliveryCity" className="form-control" placeholder="Praha" value={formData.deliveryCity} onChange={(e) => this.orderLib.formHandle(e)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6  col-md-2">
                                                                        <div className="form-group">
                                                                            <label>PSČ <span>*</span></label>
                                                                            <div>
                                                                                <input type="text" name="deliveryZip" className="form-control" placeholder="110 00" value={formData.deliveryZip} onChange={(e) => this.orderLib.formHandle(e)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {deliveryError != "" &&
                                                                        <div className="col-12">
                                                                            <Error text={deliveryError} />
                                                                        </div>
                                                                    }
                                                                    {deliveryMessage != "" &&
                                                                        <div className="col-12">
                                                                            <Message text={deliveryMessage} />
                                                                        </div>
                                                                    }

                                                                    <div className="col-6 col-md-3">
                                                                        <Mutation
                                                                            mutation = {ADD_EDIT_DELIVERY_ADDRESS}
                                                                            variables = {{deliveryAddressID:formData.deliveryAddressID}}
                                                                            onCompleted = {(data) => {
                                                                                this.setState({deliveryMessage:(addEditDeliveryAddress == 1 ? "Úspěšně přidáno" : "Úspěšně upraveno"),formData:{...this.state.formData,deliveryAddressID:data.addEditDeliveryAddress.deliveryAddressID}},() => {
                                                                                    setTimeout(() => this.setState({deliveryMessage:""}),4000);
                                                                                });
                                                                            }}
                                                                            update = {async (cache,response) => {
                                                                                this.orderLib.updateDeliveryAddresses(cache,response,formData.customerID,formData.deliveryAddressID);
                                                                            }}
                                                                            onError={(err) => {
                                                                                var helper = new Helper();
                                                                                this.setState({deliveryError:helper.getApolloErrorText(err)},() => {
                                                                                    setTimeout(() => this.setState({deliveryError:""}),4000);
                                                                                });
                                                                            }}
                                                                        >
                                                                        {
                                                                            (addEditDelAdd,{error,loading}) => {

                                                                                if(loading) return (<Loading />);
                                                                                return(
                                                                                    <button onClick={() => this.orderLib.addEditDeliveryAddress(addEditDelAdd)} className="btn btn-primary btn-block">{(addEditDeliveryAddress == 1 ? "Přidat" : "Upravit")}</button>
                                                                                )
                                                                            }    
                                                                        }
                                                                        </Mutation>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    }

                                                    {deleteDeliveryAddress &&
                                                        <div className="col-12">
                                                            <div className="address mt-2">
                                                                <div className="row">

                                                                    <div className="col-12 address-header">
                                                                        <strong>Smazání doručovací adresy</strong>
                                                                    </div>
                                                                
                                                                    <Mutation
                                                                        mutation = {DELETE_DELIVERY_ADDRESS}
                                                                        variables = {{deliveryAddressID:formData.deliveryAddressID}}
                                                                        onCompleted = {(data) => {
                                                                            this.setState({deliveryMessage:"Úspěšně smazáno",formData:{...this.state.formData,deliveryAddressID:0}},() => {
                                                                                setTimeout(() => this.setState({deliveryMessage:"",deleteDeliveryAddress:false}),4000);
                                                                            });
                                                                        }}
                                                                        update = {async (cache,response) => {
                                                                            this.orderLib.updateDeliveryAddressesAfterDelete(cache,response,formData.customerID,formData.deliveryAddressID);
                                                                        }}
                                                                        onError={(err) => {
                                                                            var helper = new Helper();
                                                                            this.setState({deliveryError:helper.getApolloErrorText(err)},() => {
                                                                                setTimeout(() => this.setState({deliveryError:"",deleteDeliveryAddress:false}),4000);
                                                                            });
                                                                        }}
                                                                    >
                                                                    {
                                                                        (delDeliveryAdd,{error,loading}) => {

                                                                            if(loading) return (<Loading />);
                                                                            return(
                                                                                <Fragment>

                                                                                    {deliveryError != "" &&
                                                                                        <div className="col-12">
                                                                                            <Error text={deliveryError} />
                                                                                        </div>
                                                                                    }
                                                                                    {deliveryMessage != "" &&
                                                                                        <div className="col-12">
                                                                                            <Message text={deliveryMessage} />
                                                                                        </div>
                                                                                    }

                                                                                    {deliveryError == "" && deliveryMessage == "" && 
                                                                                        <Fragment>
                                                                                            <div className="col-6 col-md-4">
                                                                                                <button className="btn btn-primary btn-block " onClick={() => delDeliveryAdd({variables:{deliveryAddressesID:formData.deliveryAddressID}})}>Ano</button>
                                                                                            </div>
                                                                                            <div className="col-6 col-md-4">
                                                                                                <button className="btn btn-danger btn-block" onClick={() => this.orderLib.closeDelete()}>Ne</button>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    }
                                                                                </Fragment>
                                                                            )
                                                                        }    
                                                                    }
                                                                    </Mutation>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                        
                                            }

                                        </div>
                                    </div>
                                )

                            }}
                                
                        </Query>
                    </div>
                </section>

                <section>
                    <div className="header">
                        <div className="sequence">5</div>
                        Poznámka
                    </div>
                    <div className="content">

                        <label>Poznámka</label>
                        <div>
                            <textarea className="form-control" name="note" value={formData.note} onChange={(e) => this.orderLib.formHandle(e)} ></textarea>
                        </div>
                        
                    </div>
                    {err != "" &&
                        <Error text={err} />
                    }
                    {message != "" &&
                        <Message text={message} />
                    }
                    
                    <Mutation
                        mutation = {ADD_ORDER}
                        onCompleted = {(data) => {
                            this.setState({message:"Děkujeme, objednávka byla úspěšně odeslána. Na Váš email jsme zaslali rekapitulaci objednávky."},() => {
                                setTimeout(() => this.setState({message:""}),4000);
                            });
                        }}
                        onError={(err) => {
                            var helper = new Helper();
                            this.setState({err:helper.getApolloErrorText(err)},() => {
                                setTimeout(() => this.setState({err:""}),4000);
                            });
                        }}
                    >
                    {
                        (addOrder,{error,loading}) => {

                            if(loading) return (<Loading />);
                            return(
                                <div className="text-center"><button onClick={() => this.orderLib.addOrder(addOrder)} className="btn btn-primary">Odeslat objednávku</button></div>
                            )
                        }    
                    }
                    </Mutation>
                </section>
            </div>
   
        );

    }

} 

export default withApollo(Order);