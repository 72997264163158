/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

/**
 * vložení a editace zákazníka
 */
export const ADD_EDIT_CUSTOMER_ACCOUNT = gql`
    mutation AddEditCustomerAccount(
        $customerAccountID:ID,
        $customerID:ID,
        $email: String,
        $tel: String       
    ){
        addEditCustomerAccount(
            customerAccountID:   $customerAccountID, 
            customerID:      $customerID,
            email:           $email,
            tel:             $tel
        ){
            customerAccountID
            email
            tel      
        }
    }
`;


/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER_ACCOUNT = gql`
    query CustomerAccount($customerAccountID: ID!){
        customerAccount(customerAccountID:$customerAccountID){
            customerAccountID
            email
            tel
        }
        
    }
`;

/**
 *  Seznam veškerých zákazníků
 */
export const GET_CUSTOMER_ACCOUNTS = gql`
    query AllCustomerAccounts($customerID:ID){
        allCustomerAccounts(customerID:$customerID){
            customerAccountID
            email
            tel
        }
        
    }
`;


/**
 * Smazání doručovacích adres.
 */
export const DELETE_CUSTOMER_ACCOUNT = gql`
    mutation DeleteCustomerAccounts($customerAccountsID:ID!) {
        deleteCustomerAccounts(customerAccountsID:$customerAccountsID)
    }
`;

export const GET_CUSTOMER_ACCOUNT_BY_EMAIL = gql`
    query CustomerAccountByEmail($email: String!,$customerID:ID!,$customerAccountID:ID!){
        customerAccountByEmail(email:$email,customerID:$customerID,customerAccountID:$customerAccountID){
            customerAccountID
        }
    }
`;
