/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import AddEditDeliveryAddress from './AddEditDeliveryAddress';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Notification from '../../../../Library/notification';
import {Query,Mutation,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import NoItems from '../../../../GlobalComponents/NoItems';
import {GET_DELIVERY_ADDRESSES,DELETE_DELIVERY_ADDRESS} from '../Queries/deliveryAddress.js';
import edit from '../../../../Public/Images/edit.svg';
import DeliveryAddressLib from '../Library/deliveryAddress';
import Helper from '../../../../Library/helper';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteDeliveryAddresses:"",
    selectedDeliveryAddresses:[],
    selectedDeliveryAddressID:0,
}

class DeliveryAddress extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal          = this.openCloseModal.bind(this);
       this.deleteDeliveryAddresses = this.deleteDeliveryAddresses.bind(this);
       this.delDeliveryAddress      = this.delDeliveryAddress.bind(this);
       this.showDelNotifi           = this.showDelNotifi.bind(this);

    }

    openCloseModal(type,deliveryAddressID){
        this.setState({
            showAdd:type,
            selectedDeliveryAddressID:deliveryAddressID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteDeliveryAddresses funkce, která posílá data na server
     * @param {*} deliveryAddressID ID doručovací asdresy
     */
    
    deleteDeliveryAddresses(e,deleteDeliveryAddresses,deliveryAddressID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedDeliveryAddresses];

        if(!checked){
            for(let i in this.state.selectedDeliveryAddresses){
                if(this.state.selectedDeliveryAddresses[i] == deliveryAddressID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [deliveryAddressID,...arr];
        }

        
        this.setState({
            deleteDeliveryAddresses,
            selectedDeliveryAddresses:arr
        });
  
    }

    delDeliveryAddress(action){

        if(action){

            let deliveryAddressID = this.state.selectedDeliveryAddresses.join(",");

            this.state.deleteDeliveryAddresses({
                variables:{
                    deliveryAddressesID: deliveryAddressID
                }
            })
        }
        this.setState({
            showAdd : false,
            showDeleteNotifi:false,
            deleteDeliveryAddresses:"",
            selectedDeliveryAddresses:[],
            selectedDeliveryAddressID:0
        });
    }

    showDelNotifi(){

        if(this.state.selectedDeliveryAddresses.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste doručovací adresu.',false,true,this.props.client);
        }
    }

    render(){

        const {selectedDeliveryAddresses,selectedDeliveryAddressID} = this.state;
        const {customerID} = this.props;

        return(

            <div>
                        
                <Query 
                    query={GET_DELIVERY_ADDRESSES}
                    variables = {{customerID}}
                    fetchPolicy = {'network-only'}
                >
                    {({data,loading,error,fetchMore }) => {
                        
                        if(loading) return (<Loading />);
                        if(error){
                            const helper = new Helper(); 
                            return (<Error text={helper.getApolloErrorText(error)} />);
                        }

                        return(
                            
                            <div className="card main">
                                <div className="card-header d-flex">
                                                                               
                                    <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => {this.openCloseModal(true,0);}}>Přidat</button>
                                    <button className="btn btn-danger btn-thiner ml-2" style={{"minWidth":"160px"}} onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                </div>
                                <div className="card-body">
                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            
                                            <div className="">Firma</div>
                                            <div className="text-center">Ulice a č.p.</div>
                                            <div className="text-center">Město</div>
                                            <div className="text-center">PSČ</div>
                                            <div className="text-center">Tel.</div>
                                            <div className="text-right">Možnosti</div>
                                        </div>
                                    </div>
                                    <Mutation
                                        mutation = {DELETE_DELIVERY_ADDRESS}
                                        onError = {(error) => { 
                                            const helper = new Helper(); 
                                            let notify = new Notification();
                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                        }}

                                        update = {async (cache, response) => {

                                            let deliveryAddressLib = new DeliveryAddressLib(this);
                                            deliveryAddressLib.updateAfterDeleteDeliveryAddress(cache, response,customerID);

                                            let notify = new Notification();
                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                        
                                        }}
                                    >
                                        {
                                            (deleteDeliveryAddresses,{error}) => {
                                                                                                        
                                                return(
                                                    <Fragment>
                                                        {data.allDeliveryAddresses && data.allDeliveryAddresses.map((item,index) => {

                                                            var checked = false;
                                                            for(let i in selectedDeliveryAddresses){
                                                                if(selectedDeliveryAddresses[i] == item.deliveryAddressID)checked = true;
                                                            }
                                                                                                                            
                                                            return (
                                                            
                                                                <div key={index} className="data-list-item-content">
                                                                    
                                                                    <div>{item.company}</div>
                                                                    <div className="text-center">{item.street} {item.streetNumber}</div>
                                                                    <div className="text-center">{item.city}</div>
                                                                    <div className="text-center">{item.zip}</div>
                                                                    <div className="text-center">{item.tel}</div>
                                                                    <div className="text-right">
                                                                        <img onClick={() => this.openCloseModal(true,item.deliveryAddressID)} className="edit-icon" src={edit} /> 
                                                                        <input className="delete-checked" type="checkbox" name="deleteDeliveryAddresses[]" checked={checked} onChange = {(e) => this.deleteDeliveryAddresses(e,deleteDeliveryAddresses,item.deliveryAddressID)} />
                                                                    </div>
                                                                </div>
                                                                        
                                                            )
                                                            
                                                        })} 

                                                    </Fragment>
                                                )
                                            }
                                        }
                                    </Mutation>
                                </div>
                                {data.allDeliveryAddresses && data.allDeliveryAddresses.length == 0 &&
                                    <NoItems text={"Momentálně se zde nenachází žádné doručovací adresy."} />
                                }
                                </div>
                                
                            </div>
                                
                            );
                        }
                    }
                </Query>

                   	
                {this.state.showAdd &&
                    <AddEditDeliveryAddress customerID = {customerID} openCloseModal={this.openCloseModal} selectedDeliveryAddressID={selectedDeliveryAddressID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané doručovací adresy ?'} callback={this.delDeliveryAddress} /> }	
            
            </div>
        );

    }

}

export default withApollo(DeliveryAddress);