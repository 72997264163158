/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{ Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../Public/Images/logo.png';


class LoginHeader extends Component {
	
	  constructor(props){
		  super(props);		  		  
	  }

	  render() {
		  
	    return (
			<>
	    	<header id="web">
				<div><img src={logo} /></div>
				<h1>Objednávkový systém</h1>
			</header>
				
			</>
	    );
	  }
  
}


export default withRouter(LoginHeader);
