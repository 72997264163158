/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

/**
 * vložení a editace zákazníka
 */
export const ADD_EDIT_DELIVERY_ADDRESS = gql`
    mutation AddEditDeliveryAddress(
        $deliveryAddressID:ID,
        $customerID:ID,
        $name: String,
        $surname: String,
        $company: String,
        $tel: String,
        $street: String,
        $streetNumber: String,
        $city: String,
        $zip: String,
        $countryID:ID,
       
    ){
        addEditDeliveryAddress(
            deliveryAddressID:   $deliveryAddressID, 
            customerID:          $customerID,
            name:                $name,
            surname:             $surname,
            company:             $company,
            tel:                 $tel,
            street:              $street,
            streetNumber:        $streetNumber,
            city:                $city,
            zip:                 $zip,
            countryID:           $countryID,
            
        ){
            deliveryAddressID
            name
            surname
            company
            tel
            street
            streetNumber
            city
            zip
            countryID      
        }
    }
`;


/**
 *  Data pro jednoho zákazníka
 */

export const GET_DELIVERY_ADDRESS = gql`
    query DeliveryAddress($deliveryAddressID: ID!){
        deliveryAddress(deliveryAddressID:$deliveryAddressID){
            deliveryAddressID
            name
            surname
            company
            tel
            street
            streetNumber
            city
            zip
            countryID
        }
        allCountries{
            countryID
            title
            defaultSelected
        }
        
    }
`;

/**
 *  Seznam veškerých zákazníků
 */
export const GET_DELIVERY_ADDRESSES = gql`
    query AllDeliveryAddresses($customerID:ID){
        allDeliveryAddresses(customerID:$customerID){
            deliveryAddressID
            name
            surname
            company
            tel
            street
            streetNumber
            city
            zip
            countryID
        }
        
    }
`;


/**
 * Smazání doručovacích adres.
 */
export const DELETE_DELIVERY_ADDRESS = gql`
    mutation DeleteDeliveryAddresses($deliveryAddressesID:ID!) {
        deleteDeliveryAddresses(deliveryAddressesID:$deliveryAddressesID)
    }
`;
