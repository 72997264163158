/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../../Modules/Admin/Auth/Components/WithAuthentication';
import Customer from '../../Modules/Admin/Customer/Components/Customer';
import Orders from '../../Modules/Admin/Order/Components/Orders';
import ChangePassword from '../../Modules/Admin/Auth/Components/ChangePassword';
import ImportDat from '../../Modules/Admin/Customer/Components/CustomerImport';

class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/admin/customer' component={Customer}></Route>
					<Route exact path='/admin/orders' component={Orders}></Route>
					<Route exact path='/admin/settings/change-password' component={ChangePassword}></Route>
					
					<Route exact path='/admin/import-zakazniku-do-db' component={ImportDat}></Route>
					
				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
