/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import AddEditCustomer from './AddEditCustomer';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Notification from '../../../../Library/notification';
import SearchInput from '../../../../GlobalComponents/SearchInput';
import {Query,Mutation,withApollo} from 'react-apollo';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import NoItems from '../../../../GlobalComponents/NoItems';
import {GET_CUSTOMERS,DELETE_CUSTOMER} from '../Queries/customer.js';
import edit from '../../../../Public/Images/edit.svg';
import CustomerLib from '../Library/customer';
import Helper from '../../../../Library/helper';

const INITIAL_STATE = {
    showAdd : false,
    showDeleteNotifi:false,
    deleteCustomers:"",
    selectedCustomers:[],
    selectedCustomerID:0,
    customerListVariables:{
        limit:50,
        offset:0,
        text:""
    }
}

class Customer extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       this.openCloseModal     = this.openCloseModal.bind(this);
       this.deleteCustomer     = this.deleteCustomer.bind(this);
       this.delCustomer        = this.delCustomer.bind(this);
       this.showDelNotifi      = this.showDelNotifi.bind(this);

    }

    openCloseModal(type,customerID){
        this.setState({
            showAdd:type,
            selectedCustomerID:customerID
        })
    }

    /**
     * smazání štítků
     * @param {*} deleteCustomers funkce, která posílá data na server
     * @param {*} customerID ID zákazníka
     */
    
    deleteCustomer(e,deleteCustomers,customerID){

        var checked = e.target.checked;
        var arr = [...this.state.selectedCustomers];

        if(!checked){
            for(let i in this.state.selectedCustomers){
                if(this.state.selectedCustomers[i] == customerID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [customerID,...arr];
        }

        
        this.setState({
            deleteCustomers,
            selectedCustomers:arr
        });
  
    }

    delCustomer(action){

        if(action){

            let customersID = this.state.selectedCustomers.join(",");

            this.state.deleteCustomers({
                variables:{
                    customerID: customersID
                }
            })
        }
        this.setState({
            showAdd : false,
            showDeleteNotifi:false,
            deleteCustomers:"",
            selectedCustomers:[],
            selectedCustomerID:0
        });
    }

    showDelNotifi(){

        if(this.state.selectedCustomers.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste zákazníka.',false,true,this.props.client);
        }
    }

    render(){

        const {selectedCustomers,selectedCustomerID,customerListVariables} = this.state;

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        
                        <Query 
                            query={GET_CUSTOMERS}
                            variables = {customerListVariables}
                            fetchPolicy = {'network-only'}
                        >
                            {({data,loading,error,fetchMore }) => {
                                
                                if(loading) return (<Loading />);
                                if(error){
                                    const helper = new Helper(); 
                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                }

                                return(
                                    <div className="card main">
                                        <div className="card-header d-flex align-items-center">
                                            <SearchInput value={customerListVariables.text} className="form-control" placeholder="Vyhledat zákazníka" onChange={(text) => this.setState({customerListVariables:{...this.state.customerListVariables,offset:0,text:text}})} />
                                                                                        
                                            <button className="btn btn-primary btn-thiner ml-5" onClick={(e) => {this.openCloseModal(true,0);}}>Přidat</button>
                                            <button className="btn btn-danger btn-thiner ml-2" style={{"minWidth":"160px"}} onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        </div>
                                        <div className="card-body">
                            
                            
                                            <div>
                                                <div className="data-list">
                                                    
                                                    <div className="data-list-item header">
                                                        <div className="data-list-item-content">
                                                            <div>SAP kód</div>
                                                            <div className="text-center">Jméno</div>
                                                            <div className="text-center">Příjmení</div>
                                                            <div className="text-center">Firma</div>
                                                            <div className="text-center">Email</div>
                                                            <div className="text-center">Tel.</div>
                                                            <div className="text-right">Možnosti</div>
                                                        </div>
                                                    </div>
                                                    <Mutation
                                                        mutation = {DELETE_CUSTOMER}
                                                        onError = {(error) => { 
                                                            const helper = new Helper(); 
                                                            let notify = new Notification();
                                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                        }}

                                                        update = {async (cache, response) => {

                                                            let customerLib = new CustomerLib(this);
                                                            customerLib.updateAfterDeleteCustomer(cache, response, customerListVariables);

                                                            let notify = new Notification();
                                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                        
                                                        }}
                                                    >
                                                        {
                                                            (deleteCustomer,{error}) => {
                                                                                                                        
                                                                return(
                                                                    <Fragment>
                                                                        {data.allCustomers && data.allCustomers.map((item,index) => {

                                                                            var checked = false;
                                                                            for(let i in selectedCustomers){
                                                                                if(selectedCustomers[i] == item.customerID)checked = true;
                                                                            }
                                                                                                                                            
                                                                            return (
                                                                            
                                                                                <div key={index} className="data-list-item-content">
                                                                                    
                                                                                    <div>{item.sapCode}</div>
                                                                                    <div className="text-center">{item.name}</div>
                                                                                    <div className="text-center">{item.surname}</div>
                                                                                    <div className="text-center">{item.company}</div>
                                                                                    <div className="text-center">{item.email}</div>
                                                                                    <div className="text-center">{item.tel}</div>
                                                                                    <div className="text-right">
                                                                                        <img onClick={() => this.openCloseModal(true,item.customerID)} className="edit-icon" src={edit} /> 
                                                                                        <input className="delete-checked" type="checkbox" name="deleteCustomer[]" checked={checked} onChange = {(e) => this.deleteCustomer(e,deleteCustomer,item.customerID)} />
                                                                                    </div>
                                                                                </div>
                                                                                        
                                                                            )
                                                                            
                                                                        })} 
 
                                                                    </Fragment>
                                                                )
                                                            }
                                                        }
                                                    </Mutation>
                                                </div>
                                                {data.allCustomers && data.allCustomers.length == 0 ?
                                                    <NoItems text={"Momentálně se zde nenachází žádní zákazníci."} />
                                                :
                                                    <div className="text-center p-5">
                                                        <button className="btn btn-primary" onClick={() => fetchMore({
                                                            variables: {
                                                                offset: data.allCustomers.length
                                                            },
                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                if (!fetchMoreResult) return prev;
                                                                return Object.assign({}, prev, {
                                                                    allCustomers: [...prev.allCustomers, ...fetchMoreResult.allCustomers]
                                                                });
                                                            }
                                                        })}> Načíst další zákazníky </button>
                                                    </div> 

                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                    );
                                }
                            }
                        </Query>

                    </div>
                </div> 	
                {this.state.showAdd &&
                    <AddEditCustomer customerListVariables = {customerListVariables} openCloseModal={this.openCloseModal} selectedCustomerID={selectedCustomerID} />
                }	
                {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané zákazníky ?'} callback={this.delCustomer} /> }	
            
            </div>
        );

    }

}

export default withApollo(Customer);