import React, {Component,Fragment} from 'react';
import {ApolloClient} from 'apollo-client';
import { ApolloProvider} from "react-apollo";
import {ApolloLink,concat} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {GRAPHQL_SERVER_URL} from './Config/index';

// routování url adres
import Routes from './Routes/index.js'; 

//resolvery a schema pro lokalní uložení
import {resolvers, typeDefs} from './Resolvers/resolvers';
import { GET_CUTOMER_DATA } from './Modules/Client/Order/Queries/order';
import Loading from './GlobalComponents/Loading';
import { GET_ADMIN_DATA_CONTEXT } from './Modules/Admin/Auth/Queries/Login';

// inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
const cache  = new InMemoryCache();

// URl adresa na server pro graphql
const httpLink = new createUploadLink({ 
	uri: GRAPHQL_SERVER_URL,
	 
});

//prostředník, který při každém requestu zasílá token na server z localstorage
const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	operation.setContext({
	  headers: {
		authorization: localStorage.getItem('token') || null,
	  }
	});
  
	return forward(operation);
});


//Apollo klient, který se přihlašuje na server
const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink), 
  resolvers,
  typeDefs
});



//do cache se ukládá, jestli jsme přihlášení nebo ne
cache.writeData({
	data:{
		isLoggedIn: !!localStorage.getItem('token'),
		loginPlace: localStorage.getItem('loginPlace') || "",
		notifications:[]
	}
});



class App extends Component {
  
	constructor(props){
		super(props);		
		this.state = {
			loading:true		  
		}
	}

	componentDidMount() {				
		this.getInitialUserData();	
	}
	
	getInitialUserData = async () => {
	
        await client.query({
            query:GET_CUTOMER_DATA
		});
        await client.query({
            query:GET_ADMIN_DATA_CONTEXT
		});
		
		this.setState({loading:false});
	}

    render(){	
	    return (
			<Fragment>
				{!this.state.loading ? 
					<ApolloProvider client={client}>
						<Routes />	   
					</ApolloProvider>
					:
					<Loading />
				}
			</Fragment>
	    );
    }
}

export default App;
