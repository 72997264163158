/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací doručovacích adres
 */

import {GET_DELIVERY_ADDRESS,GET_DELIVERY_ADDRESSES} from '../Queries/deliveryAddress.js';
import Notification from '../../../../Library/notification';

class DeliveryAddress {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
     */

    async getData(){

        var deliveryAddressID = 0;
        if(this.parent.props.selectedDeliveryAddressID)deliveryAddressID = this.parent.props.selectedDeliveryAddressID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_DELIVERY_ADDRESS,
            errorPolicy:"all",
            variables:{deliveryAddressID}, 
            fetchPolicy: 'network-only'
        });

        console.log(data);

        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }

        if(data.data){

            var allCountries = [];
            var deliveryCountryID = 0;

            if(data.data.allCountries && data.data.allCountries.length > 0){
                allCountries =  data.data.allCountries;
                for(let val of allCountries){
                    if(val.defaultSelected == 1){
                        deliveryCountryID = val.countryID;
                    }
                }
            }
            
            if(data.data.deliveryAddress){
                
                this.parent.setState({
                    apolloError:"",selectedDeliveryAddressID:deliveryAddressID, allCountries,
                    formData:{...this.parent.state.formData,
                        deliveryAddressID:   data.data.deliveryAddress.deliveryAddressID,
                        name:                data.data.deliveryAddress.name,
                        surname:             data.data.deliveryAddress.surname,
                        company:             data.data.deliveryAddress.company,
                        tel:                 data.data.deliveryAddress.tel,
                        street:              data.data.deliveryAddress.street,
                        streetNumber:        data.data.deliveryAddress.streetNumber,
                        city:                data.data.deliveryAddress.city,
                        zip:                 data.data.deliveryAddress.zip,
                        countryID:           data.data.deliveryAddress.countryID,
                        
  
                    }
                });

            }else{
                this.parent.setState({ apolloError:"", allCountries,formData:{...this.parent.state.formData, deliveryAddressID, deliveryCountryID }});
            }
        }
    }

    /**
     * Přidání nebo úprava nového zákazníka
     * @param {*} addDeliveryAddress funkce z apollo, pomocí které se posílají data na server
     */
    async addDeliveryAddress(addDeliveryAddress,customerID){

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.company != ""){         
            if(data.street != ""){
                if(data.streetNumber != ""){
                    if(data.city != ""){
                        if(data.zip != ""){
                            if(data.countryID != 0){
                               
                                if(data.tel != ""){
                                
                                    data.customerID = customerID;
                                    console.log(data);
                                    addDeliveryAddress({
                                        variables:data
                                    });     

                                }else{
                                    notify.setNotification(null,'Nevyplnili jste telefon.',false,true,this.parent.props.client); 
                                }     
                                
                            }else{
                                notify.setNotification(null,'Nevyplnili jste stát.',false,true,this.parent.props.client); 
                            }
                        }else{
                            notify.setNotification(null,'Nevyplnili jste PSČ.',false,true,this.parent.props.client); 
                        }
                    }else{
                        notify.setNotification(null,'Nevyplnili jste město.',false,true,this.parent.props.client); 
                    }
                }else{
                    notify.setNotification(null,'Nevyplnili jste číslo popisné.',false,true,this.parent.props.client); 
                }
            }else{
                notify.setNotification(null,'Nevyplnili jste ulici.',false,true,this.parent.props.client); 
            }
           
        }else{
            notify.setNotification(null,'Nevyplnili jste společnost.',false,true,this.parent.props.client);
        }

    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {*} cache apollo cahe v které jsou oložena data
     * @param {*} response položka, která byla vložena nebo upravována
     */

    updateDeliveryAddressList(cache,response,selectedDeliveryAddressID,customerID){

        const { allDeliveryAddresses } = cache.readQuery({ query: GET_DELIVERY_ADDRESSES,variables:{customerID}});

        if(!(selectedDeliveryAddressID && selectedDeliveryAddressID != 0)){

            cache.writeQuery({ 
                query:GET_DELIVERY_ADDRESSES,
                variables:{customerID},
                data:{
                    allDeliveryAddresses: [response.data.addEditDeliveryAddress,...allDeliveryAddresses]
                } 
            });

        }else{

            var arr = [...allDeliveryAddresses];
            
            arr.forEach((item,index) => {
                if(item.deliveryAddressID == response.data.addEditDeliveryAddress.deliveryAddressID){
                    arr[index] = response.data.addEditDeliveryAddress;
                }

            });

            cache.writeQuery({ 
                query:GET_DELIVERY_ADDRESSES,
                variables:{customerID},
                data:{
                    allDeliveryAddresses: arr
                } 
            });
        }
    }

    /**
     * Update seznamu doručovacích adres po smazání vybraných doručovacích adres
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     */
    
    updateAfterDeleteDeliveryAddress(cache, response,customerID){

        var resp = response.data.deleteDeliveryAddresses.split(",");

        const { allDeliveryAddresses } = cache.readQuery({ query: GET_DELIVERY_ADDRESSES,variables:{customerID}});
        var arr = [...allDeliveryAddresses];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.deliveryAddressID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
        
        cache.writeQuery({ 
            query:GET_DELIVERY_ADDRESSES,
            variables:{customerID},
            data:{
                allDeliveryAddresses: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default DeliveryAddress;