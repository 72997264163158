/*
    Úvodní layout administrace
*/
import React,{ Component,Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';
import {Switch, Route } from 'react-router-dom';
import Order from '../../Modules/Client/Order/Components/Order';
import Orders from '../../Modules/Client/Order/Components/Orders';
import withAuthentication from '../../Modules/Client/Auth/Components/WithAuthentication';

class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div id="client-container">				       
 	      
			<Header /> 
			<div id="client-content" className="container page">
				<Switch>
					<Route exact path='/objednavka' component={Order}></Route>
					<Route exact path='/predchozi-objednavky' component={Orders}></Route>
				</Switch>
			</div> 
			<Footer />
		     	       
		</div>
   
    );
  }
}

export default withAuthentication(Layout);
