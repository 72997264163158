import React,{ Component,Fragment } from 'react';
import Header from '../../../../Layout/Client/Header';
import Footer from '../../../../Layout/Client/Footer';
import {Mutation,withApollo} from 'react-apollo';
import {SAVE_NEW_PASSWORD} from '../Queries/changePassword';
import { NavLink } from "react-router-dom";
import AuthLib from '../Library/auth';

const INITIAL_STATE = {
	
	password:'',
	checkPassword:'',
	error:"",
	message:"",
	loading:false
}

class NewPassword extends Component {
		
  constructor(props){
	    
	  super(props);	 
      this.state = {...INITIAL_STATE};
      this.goBack = this.goBack.bind(this);
 
  }	

  componentDidMount(){
	  this.authLib = new AuthLib(this);
  }

  goBack(){ 
    this.props.history.push("/");
  }
    
  
  render() {

    const {error,message,loading} = this.state;
    const passCode = this.props.match.params.code;
		  
    return (

        <div id="client-container">			       
        
        <Header />  
        <div id="client-content" className="content-center">

            <Mutation
                mutation = {SAVE_NEW_PASSWORD}
                update = {async (cache, response) => {

                    if(response.data.saveNewPassword != ""){
                        this.setState({error:response.data.saveNewPassword});
                        setTimeout(() => this.setState({'error':''}),4000);
                    }else{	
                        this.setState({message:'Úspěšně změněno'});
                    }

                }}
            >
                {
                    (saveNewPassword,response) => {

                        const {checkPassword,password,error} = this.state;
                        return(
                            <div id="client-login">

                                <h2>Nové heslo</h2>

                                    <div className="form-group">
                                    <p>Zvolete si své nové heslo</p>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Heslo</label>
                                    <div>
                                        <input className="form-control" id="password" type="password" name="password" placeholder="******" 
                                        onChange={event => this.setState({'password':event.target.value})}
                                        value={password} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="checkPassword">Zopakování hesla</label>
                                    <div>
                                        <input className="form-control" id="checkPassword" type="password" name="checkPassword" placeholder="******" 
                                        onChange={event => this.setState({'checkPassword':event.target.value})}
                                        value={checkPassword} />
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    
                                    <button onClick={() => this.authLib.savePassword(saveNewPassword,passCode)} className="btn btn-primary">Uložit</button>
                                        
                                </div>

                                {error &&
                                <div className="alert alert-danger">{error}</div>
                                }
                                {message &&
                                <div className="alert alert-success">{message}</div>
                                }

                                <div className="form-group forgot-password">
                                <p><NavLink to="">Zpět na přihlášení</NavLink></p>
                                <p><a target="_blank" href="http://www.daikin.cz">Web Daikin.cz</a></p>
                                </div>

                            </div>
                        )
                    }
                }
            </Mutation>
        </div>
  
        <Footer />

      </div>
    );
  }
}

export default withApollo(NewPassword);
