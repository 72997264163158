import gql from 'graphql-tag';

export const LOGIN_CUSTOMER = gql`
  mutation LoginCustomer($email: String!,$password: String!) {
    loginCustomer(email: $email,password: $password){
        loginToken
        customer{
            name
            surname
        }
    }
  }
`;

